import { AnyAction } from '@reduxjs/toolkit';

export interface User {
  id: string;
  username?: string;
  email: string;
  country: 'US' | 'BR';
  fullName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  channelPartnerId: string;
  partnerType: string;
  partnerTier: string;
  language: 'en' | 'pt-BR';
  roles: string[];
}

interface userState {
  userData: User | null;
  allUsersList: User[] | null;
  usersList: User[] | null;
  searchFilter: string | null;
  userListState: {
    lifeCycle: string;
  };
  getUserState: {
    lifeCycle: string;
  };
  getUserPartnerActiveContracts: {
    lifeCycle: string;
  };
  userPartnerActiveContracts: [];
}

const initState: userState = {
  userData: null,
  allUsersList: null,
  usersList: null,
  searchFilter: null,
  userListState: { lifeCycle: 'NONE' },
  getUserState: { lifeCycle: 'NONE' },
  getUserPartnerActiveContracts: { lifeCycle: 'NONE' },
  userPartnerActiveContracts: [],
};

const filterList = (allUsers, searchFilter: any) => {
  if (searchFilter) {
    return allUsers.filter((u) => {
      if (searchFilter === 'US' || searchFilter === 'BR') {
        return u.app_metadata?.country && u.app_metadata?.country === searchFilter;
      }
      return u.name?.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0 || u.email?.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0;
    });
  } else {
    return allUsers;
  }
};

const reducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case 'GET_USER_LIST_REQUESTED': {
      return {
        ...state,
        userListState: { lifeCycle: 'PENDING' },
      };
    }
    case 'GET_USER_LIST_SUCCEEDED': {
      return {
        ...state,
        allUsersList: action.payload,
        usersList: filterList(action.payload, state.searchFilter),
        userListState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'GET_USER_LIST_ERROR': {
      return {
        ...state,
        userListState: { lifeCycle: 'ERROR', error: action.payload },
      };
    }
    case 'USER_SEARCH_APPLIED': {
      return {
        ...state,
        searchFilter: action.payload,
        usersList: filterList(state.allUsersList, action.payload),
      };
    }
    case 'CLEAR_USER_LIST_FILTERS': {
      return {
        ...state,
        searchFilter: null,
      };
    }
    case 'GET_USER_REQUESTED': {
      return {
        ...state,
        getUserState: { lifeCycle: 'PENDING' },
      };
    }
    case 'GET_USER_SUCCEEDED': {
      return {
        ...state,
        userData: action.payload,
        getUserState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'GET_USER_ERROR': {
      return {
        ...state,
        getUserState: { lifeCycle: 'ERROR', error: action.payload },
      };
    }
    case 'GET_USER_PARTNER_CONTRACTS_REQUESTED': {
      return {
        ...state,
        getUserPartnerActiveContracts: { lifeCycle: 'PENDING' },
      };
    }
    case 'GET_USER_PARTNER_CONTRACTS_SUCCEEDED': {
      return {
        ...state,
        userPartnerActiveContracts: action.payload,
        getUserPartnerActiveContracts: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'GET_USER_PARTNER_CONTRACTS_ERROR': {
      return {
        ...state,
        getUserPartnerActiveContracts: { lifeCycle: 'ERROR', error: action.payload },
      };
    }
  }
  return state;
};

export default reducer;

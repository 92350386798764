import axios, { ResponseType } from 'axios';
import store from '../../redux';
import { refreshSessionAction } from '../../redux/actions/context';

const getAccessToken = () => {
  const state = store.getState();
  return state.context.accessToken;
};

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const state = store.getState();
    const refreshSessionFn = state.context.refreshSessionFn;

    const originalRequest = error.config;
    if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
      originalRequest._retry = true;
      await store.dispatch(refreshSessionAction(refreshSessionFn));
      originalRequest.headers.common['Authorization'] = `Bearer ${getAccessToken()}`;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export const getStaticFilesBaseUrl = () => {
  return process.env.REACT_APP_STATIC_FILES_ENDPOINT;
};

const get = async (url: string, params?: any) => {
  return axiosApiInstance.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    params,
  });
};
const getBlob = async (url: string, params?: any) => {
  return axiosApiInstance.get(url, {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    params,
  });
};

const postBlob = async (url: string, body: any) => {
  return axiosApiInstance.post(url, body, {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

const patch = async (url: string, bodyData: any) => {
  return axiosApiInstance.patch(url, bodyData, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

const put = async (url: string, bodyData: any) => {
  return axiosApiInstance.put(url, bodyData, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

const post = async (url: string, bodyData: any, responseType?: ResponseType, headers?: any) => {
  return axiosApiInstance.post(url, bodyData, {
    responseType,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...headers,
    },
  });
};

const deleteMethod = async (url: string) => {
  return axiosApiInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

const downloadFile = async (url: string, params?: any) => {
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    responseType: 'blob',
    params,
  });
};

const downloadFileOrJson = async (url: string, params?: any) => {
  const response: any = await axiosApiInstance.post(url, params, {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response;
};

export const API = {
  getAccessToken,
  post,
  get,
  patch,
  put,
  getBlob,
  postBlob,
  delete: deleteMethod,
  downloadFile,
  downloadFileOrJson,
};

import { API } from './api';
import { MFA_URL } from './env';
import { GetUserCommand, CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider';

export const associateSoftwareToken = async (accessToken) => {
    return await API.get(`${MFA_URL}/test-mfa-cognito?accessToken=${accessToken}`).then((res) => {
        return res.data;
      });
};

export async function getUserMfaPreference (accessToken) {
  const client = new CognitoIdentityProviderClient({
      region: "us-east-2", 
    });
  const input = { 
      AccessToken: accessToken,
  };
  const command = new GetUserCommand(input);
  const response = await client.send(command);
  const getUserMfa = response.UserMFASettingList;
  return getUserMfa;
}
import { useEffect, useState } from 'react';
import { Event, EventTypes } from '../types/EventTypes';
import { MarkerHelper } from './map/MarkerHelper';
import { Feature } from '@turf/helpers';
import mapboxgl from 'mapbox-gl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FieldLayout } from '../types/FieldLayout';
import { getRandomNumber } from './common/utils';
import { Journey } from '../types/Journey';
import { states } from './map/MapContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { API } from '../../../service/common/api';
import { FIELD_URL } from '../../../service/common/env';

export const useEventService = (
  paddocks: { [key: string]: Feature[] },
  state: states,
  setState: (state: states) => void,
  currentVersion?: FieldLayout,
  map?: mapboxgl.Map,
  journey?: Journey,
) => {
  const { hideStructuresAndEvents } = useFlags();
  const [searchParams] = useSearchParams();
  const fieldId = searchParams.get('fieldId');
  const navigate = useNavigate();
  const { journeyId } = useParams();
  const [events, setEvents] = useState<any[]>([]);
  const [eventsInvalid, setEventsInvalid] = useState(false);

  function handleEventMarkers(event, newPaddocks?: Record<string, any>) {
    if (!map) {
      return;
    }

    return MarkerHelper.generateNewMarkerOnMap(
      event,
      newPaddocks ?? paddocks,
      map,
      'EVENT',
      () => {
        if (state === 'OVERVIEW' || state === 'DETAILS') {
          setState('DETAILS');
          navigate(`/v2/fields/${journeyId}/details?fieldId=${event.fieldId}&type=EVENT`);
        }
      },
      state === 'OVERVIEW' || state === 'DETAILS' ? 'pointer' : 'default',
    );
  }

  function updateEventData(updatedEventData, newPaddocks?: Record<string, any>) {
    if (!map) {
      return;
    }

    const treatedEvents = updatedEventData.map((herd) => handleEventMarkers(herd, newPaddocks));
    updatedEventData.forEach((event, index) => {
      if (treatedEvents[index]?.marker) updatedEventData[index].marker = treatedEvents[index]?.marker;
    });
    filterEventMarkers(updatedEventData);
    setEvents(updatedEventData);
  }

  function filterEventMarkers(eventData?: any[]) {
    if (!eventData) eventData = events;

    for (const event of eventData) {
      if (event.marker) {
        if (!fieldId) {
          event.marker.addTo(map);
          continue;
        }

        if (event.marker && event.fieldId !== fieldId) {
          event.marker.remove();
        } else {
          event.marker.addTo(map);
        }
      }
    }
  }

  function removeAllMarkers() {
    for (const event of events) {
      if (event.marker) event.marker.remove();
    }
  }

  useEffect(() => {
    if (state === 'SECOND_SETUP') removeAllMarkers();
    filterEventMarkers(events);
  }, [fieldId, state]);

  const getEvents = (fieldId?: string, newPaddocks?: { [key: string]: Feature[] }) => {
    if (hideStructuresAndEvents) return;

    const dateRange = {
      startDate: currentVersion?.startDate,
      endDate: currentVersion?.endDate,
    };
    const params: any = { journeyId: journey?.id, dateRange };
    if (fieldId) params.fieldId = fieldId;
    return API.post(`${FIELD_URL}/field-service/events/fetch`, params)
      .then(async (res) => {
        await clearEvents();
        const treatedEventData = res.data.map((event: any) => {
          return {
            ...event,
            data: {
              ...event.data,
              startDate: event?.data?.startDate ? new Date(event.data.startDate) : undefined,
              endDate: event?.data?.endDate ? new Date(event.data.endDate) : undefined,
            },
          };
        });
        updateEventData(treatedEventData, newPaddocks);
      })
      .catch(console.log);
  };

  function updateEvent(event: any) {
    setEvents((events) => {
      const index = events.findIndex((eventSearch) => eventSearch.id === event.id);
      events[index] = {
        ...event,
        invalid: isEventValid(event),
      };
      return [...events];
    });
  }

  const eventDateValidation = (event: Event) => event.data?.startDate && event.data?.endDate && event.data.startDate <= event.data.endDate;

  const eventTypeValidation = {
    [EventTypes.FEEDING]: (event: Event) => event.data?.subType && event.data?.amount && eventDateValidation(event),
    [EventTypes.HAYING]: (event: Event) => event.data?.amount && eventDateValidation(event),
    [EventTypes.SUPPLEMENTING]: (event: Event) => event.data?.subType && event.data?.amount && eventDateValidation(event),
    [EventTypes.FIRE]: (event: Event) =>
      event.data?.subType && event.data?.acresBurned && event.data?.yearBurnOccurred && event.data?.monthBurnOccurred,
  };

  useEffect(() => {
    setEventsInvalid(events.some(isEventValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  function isEventValid(event: Event) {
    return !(event.type && event.fieldId && event.data && event.data.name && eventTypeValidation[event.type](event));
  }

  function addEvent(fieldId?: string, fieldName?: string) {
    let paddockIds: string[] = [];
    const data: any = {
      fromField: fieldName,
      name: `Event #${events.length + 1}`,
    };
    if (fieldId && paddocks[fieldId].length === 1) {
      paddockIds = [paddocks[fieldId][0].id!.toString()];
      data.fromPaddock = paddocks[fieldId][0].properties!.name!.toString();
    }

    const treatedEvent = handleEventMarkers({
      id: getRandomNumber(),
      type: EventTypes.FEEDING,
      fieldId,
      paddockIds: paddockIds,
      data,
      invalid: true,
    });
    filterEventMarkers([treatedEvent]);
    setEvents([...events, treatedEvent]);
  }

  function removeEvent(event: any) {
    setEvents((events) => {
      if (event.marker) {
        event.marker.remove();
      }

      return events.filter((eventSearch) => eventSearch.id !== event.id);
    });
  }

  function saveEvent(event: any) {
    const body = [
      {
        ...event,
        growerId: journey?.leadId ?? journey?.opportunityId,
        journeyId: journey?.id,
        marker: undefined,
        invalid: undefined,
        id: event.id && typeof event.id === 'number' ? undefined : event.id,
      },
    ];

    return API.post(`${FIELD_URL}/field-service/events`, body).then((response) => {
      const index = events.findIndex((eventSearch: any) => eventSearch.id === event.id);
      const newEvent = response.data[0];
      setEvents((events) => {
        events[index].marker?.remove();
        events[index] = handleEventMarkers({
          ...newEvent,
          data: {
            ...newEvent.data,
            startDate: newEvent?.data?.startDate ? new Date(newEvent.data.startDate) : undefined,
            endDate: newEvent?.data?.endDate ? new Date(newEvent.data.endDate) : undefined,
          },
        });

        filterEventMarkers([events[index]]);
        return [...events];
      });
    });
  }

  function saveEvents() {
    const body = events.map((event) => {
      return {
        ...event,
        growerId: journey?.leadId ?? journey?.opportunityId,
        journeyId: journey?.id,
        marker: undefined,
        invalid: undefined,
        id: event.id && typeof event.id === 'number' ? undefined : event.id,
      };
    });

    return API.post(`${FIELD_URL}/field-service/events`, body).then((res) => {
      clearEvents().then(() => {
        updateEventData(res.data);
      });
    });
  }

  async function clearEvents() {
    for (const event of events) {
      if (event.marker) event.marker.remove();
    }

    setEvents([]);
  }

  return {
    getEvents,
    clearEvents,
    handleEventMarkers,
    filterEventMarkers,
    updateEventData,

    events,
    eventsInvalid,
    setEvents,
    addEvent,
    updateEvent,
    removeEvent,
    saveEvent,
    saveEvents,
  };
};

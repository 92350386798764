import {
  DCEventTypes,
  EventTypes,
  IDCHarvestEvent,
  IDCPlantingEvent,
  IEvents,
  IEventsToDelete,
} from '../../pages/customer-profile/components/Tabs/DataCollectionTab/interfaces';
import { DataCollectionService } from '../../service/data-collection/data-collection.service';
import { v4 as uuidv4 } from 'uuid';
import { Journey } from '../../pages/customer-profile/types/GrowerService';
import AgoToast from '../../components/Toast/AgoToast';
import { DataCollectionModes } from '../../pages/customer-profile/components/Tabs/DataCollectionTab/components/row-crop/RowCropFieldView';

export const getEventsTypes: any = (journeyId: string) => (dispatch: any) => {
  dispatch({ type: 'GET_EVENTS_TYPES_REQUESTED' });
  return DataCollectionService.getEventsConfiguration(journeyId)
    .then((res) => {
      dispatch({ type: 'GET_EVENTS_TYPES_SUCCEEDED', payload: res });
    })
    .catch((err: any) => {
      dispatch({ type: 'GET_EVENTS_TYPES_ERROR', payload: err });
    });
};

export const getImplementationYear: any = (journeyId: string) => (dispatch: any) => {
  dispatch({ type: 'GET_IMPLEMENTATION_YEAR_REQUESTED' });
  return DataCollectionService.getImplementationYear(journeyId)
    .then((res) => {
      dispatch({ type: 'GET_IMPLEMENTATION_YEAR_SUCCEEDED', payload: res });
    })
    .catch((err: any) => {
      dispatch({ type: 'GET_IMPLEMENTATION_YEAR_ERROR', payload: err });
    });
};

export const getFarmInformation: any = (journeyId: string) => (dispatch: any) => {
  dispatch({ type: 'GET_FARM_INFORMATION_REQUESTED' });
  return DataCollectionService.getFarmInformation(journeyId)
    .then((res) => {
      dispatch({ type: 'GET_FARM_INFORMATION_SUCCEEDED', payload: res });
    })
    .catch((err: any) => {
      dispatch({ type: 'GET_FARM_INFORMATION_ERROR', payload: err });
    });
};

export const upsertImplementationYear: any = (journeyId: string, opportunityId: string, implementationYear: number) => (dispatch: any) => {
  dispatch({ type: 'UPSERT_IMPLEMENTATION_YEAR_REQUESTED' });
  return DataCollectionService.upsertImplementationYear({ journeyId, opportunityId, implementationYear })
    .then((res) => {
      dispatch({ type: 'UPSERT_IMPLEMENTATION_YEAR_SUCCEEDED', payload: res });
    })
    .catch((err: any) => {
      dispatch({ type: 'UPSERT_IMPLEMENTATION_YEAR_ERROR', payload: err });
    });
};

export const upsertGrazingSeason: any = (journeyId: string, opportunityId: string, startDate: Date, endDate: Date) => (dispatch: any) => {
  dispatch({ type: 'UPSERT_GRAZING_SEASON_REQUESTED' });
  return DataCollectionService.upsertGrazingSeason({ journeyId, opportunityId, startDate, endDate })
    .then((res) => {
      dispatch({ type: 'UPSERT_GRAZING_SEASON_SUCCEEDED', payload: res });
    })
    .catch((err: any) => {
      dispatch({ type: 'UPSERT_GRAZING_SEASON_ERROR', payload: err });
    });
};

export const getStateLocationRelations: any = () => (dispatch: any) => {
  dispatch({ type: 'GET_STATE_LOCATION_RELATIONS_REQUESTED' });
  return DataCollectionService.getStateLocationRelations()
    .then((res) => {
      dispatch({ type: 'GET_STATE_LOCATION_RELATIONS_SUCCEEDED', payload: res });
    })
    .catch((err: any) => {
      dispatch({ type: 'GET_STATE_LOCATION_RELATIONS_ERROR', payload: err });
    });
};

export const getModelInputDefaults: any = () => (dispatch: any) => {
  DataCollectionService.getModelInputDefaults().then((res) => {
    dispatch({ type: 'GET_MODEL_INPUT_DEFAULTS_SUCCEEDED', payload: res.data });
  });
};

export const addNewEvent: any = (eventToAdd: IEvents, eventType: EventTypes) => (dispatch: any) => {
  dispatch({
    type: 'ADD_NEW_EVENT',
    payload: {
      eventType,
      eventToAdd,
    },
  });
};

export const harvestEventsUpdated: any = (eventsUpdated: IDCHarvestEvent) => (dispatch: any) => {
  dispatch({
    type: 'HARVEST_EVENTS_UPDATED',
    payload: {
      eventsUpdated,
    },
  });
};

export const updateEvent: any = (event: Record<string, any>, eventType: EventTypes, shouldScroll: boolean) => (dispatch: any) => {
  dispatch({
    type: 'UPDATE_EVENT',
    payload: {
      eventType,
      event,
    },
  });
  if (shouldScroll) scrollToEventOrField(event);
};

export const eventUpdated: any = (event: Record<string, any>, eventType: EventTypes) => (dispatch: any) => {
  dispatch({
    type: 'EVENT_UPDATED',
    payload: {
      eventType,
      event,
    },
  });
};

export const cancelEdit: any = () => (dispatch: any) => {
  dispatch({
    type: 'CANCEL_EDIT',
  });
};

export const saveEvents: any = (events, eventsDeleted: IEventsToDelete[], journey: Journey, eventFieldId) => (dispatch: any) => {
  dispatch({ type: 'SAVE_EVENT_REQUESTED' });
  return Promise.all([DataCollectionService.saveEvents(events, journey, eventsDeleted, eventFieldId), DataCollectionService.deleteEvents(eventsDeleted)])
    .then(([saveRes]) => {
      dispatch({
        type: 'SAVE_EVENT_SUCCEEDED',
        payload: saveRes.data,
      });
      dispatch({
        type: 'DELETE_EVENT_SUCCEEDED',
        payload: eventsDeleted,
      });
      if (saveRes.status === 207) {
        AgoToast.showToast({
          title: 'Partial Error Saving Events',
          message: 'Some events failed to save, please contact IT for more information',
          type: 'warning',
          toastId: 'events-save-request',
        });
      }
      AgoToast.showToast({
        title: 'Success',
        message: 'The events have been saved',
        type: 'success',
        toastId: 'events-save-request',
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'SAVE_EVENT_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'Error Saving Events',
        message: err.message,
        type: 'error',
        toastId: 'events-save-request',
      });
    });
};

export const markEventAsDeleted: any = (event, eventType: EventTypes) => (dispatch: any) => {
  if (event.newEvent) {
    return dispatch({
      type: 'REMOVE_NEW_EVENT',
      payload: {
        eventId: event.id,
        eventType,
      },
    });
  }

  return dispatch({
    type: 'MARK_EVENT_AS_DELETED',
    payload: {
      eventId: event.id,
      eventType,
    },
  });
};

export const handleEventDelete: any = (type: EventTypes, id: string, fieldId: string) => (dispatch: any) => {
  dispatch({
    type: 'DELETE_MODAL_REQUEST',
    payload: [
      {
        name: type,
        eventIds: [id],
        fieldId: [fieldId],
      },
    ],
  });
};

export const cancelEventDelete: any = () => (dispatch: any) => {
  return dispatch({
    type: 'CANCEL_EVENT_DELETE',
  });
};

export const deleteSingleEvent: any =
  (singleEventDelete: IEventsToDelete[], journey: string, mode: DataCollectionModes) => (dispatch: any, getState: any) => {
    dispatch({ type: 'DELETE_SINGLE_EVENT_REQUESTED' });
    return DataCollectionService.deleteEvents(singleEventDelete)
      .then(() => {
        dispatch({
          type: 'DELETE_EVENT_SUCCEEDED',
          payload: singleEventDelete,
        });
      })
      .then(() => {
        const eventDeletedFieldId = singleEventDelete[0]?.fieldId;
        const eventsToUpdate = DataCollectionService.treatedEventTypes(getState().dataCollection.dataCollectionEventsTypes, mode === 'ADD');
        dispatch(saveEvents(eventsToUpdate, [], journey, eventDeletedFieldId));
      })
      .catch((err: any) => {
        dispatch({
          type: 'DELETE_EVENT_ERROR',
          payload: err,
        });
        AgoToast.showToast({
          title: 'Error Deleting Events',
          message: err.message,
          type: 'error',
          toastId: 'events-delete-request',
        });
      });
  };

export const duplicateEvent: any = (event, eventType: EventTypes, journey: Journey, eventFieldId='') => (dispatch: any, getState: any) => {
  const duplicatedEvent = { ...event, id: uuidv4() };
  const eventToSave = {};
  const currentEvents = Object.values(getState().dataCollection.dataCollectionEventsTypes[eventType].events);
  eventToSave[eventType] = { name: eventType, events: [...currentEvents, duplicatedEvent] };
  dispatch({ type: 'DUPLICATE_EVENT_REQUESTED', payload: { duplicatedEvent, eventType, eventId: event.id } });
  return DataCollectionService.saveEvents(eventToSave as DCEventTypes, journey, [], eventFieldId)
    .then((saveRes) => {
      dispatch({ type: 'DUPLICATE_EVENT_SUCCEEDED', payload: { events: saveRes.data.eventsSuccess, eventType, eventId: event.id } });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: 'DUPLICATE_EVENT_ERROR', payload: { duplicatedEvent, eventType, eventId: event.id } });
    });
};

export const duplicatePlantingEvent: any =
  (event: IDCPlantingEvent, duplicateHarvestEvents: IDCHarvestEvent[], journey: Journey, eventFieldId='') => (dispatch: any, getState: any) => {
    const duplicatedPlantingEvent = { ...event, id: uuidv4() };
    const eventsToSave = {};
    const plantingEvents = Object.values(getState().dataCollection.dataCollectionEventsTypes.Planting.events);
    eventsToSave['Planting'] = { name: 'Planting', events: [...plantingEvents, duplicatedPlantingEvent] };
    const duplicatedHarvestEvents = duplicateHarvestEvents.map((he) => {
      return { ...he, id: uuidv4(), plantingId: duplicatedPlantingEvent.id };
    });
    const harvestEvents = Object.values(getState().dataCollection.dataCollectionEventsTypes.Harvest.events);
    eventsToSave['Harvest'] = {
      name: 'Harvest',
      events: [...harvestEvents, ...duplicatedHarvestEvents],
    };
    dispatch({ type: 'DUPLICATE_PLANTING_EVENT_REQUESTED', payload: { duplicatedHarvestEvents, duplicatedPlantingEvent, eventId: event.id } });
    return DataCollectionService.saveEvents(eventsToSave as DCEventTypes, journey, [], eventFieldId)
      .then((saveRes) => {
        dispatch({
          type: 'DUPLICATE_PLANTING_EVENT_SUCCEEDED',
          payload: { eventsDuplicated: saveRes.data.eventsSuccess, eventId: event.id },
        });
      })
      .catch(() => {
        dispatch({ type: 'DUPLICATE_PLANTING_EVENT_ERROR', payload: { duplicatedHarvestEvents, duplicatedPlantingEvent, eventId: event.id } });
      });
  };

export const filterEvents: any = (fieldIds: string[], years: number[], events: string[], showOnlyErrors: boolean) => (dispatch: any) => {
  dispatch({
    type: 'FILTER_EVENTS',
    payload: {
      fieldIds,
      years,
      events,
      showOnlyErrors,
    },
  });
};

export const bulkCopyEvents: any = (eventsTypes) => (dispatch: any) => {
  dispatch({
    type: 'BULK_COPY_EVENTS',
    payload: {
      eventsTypes,
    },
  });
};

function scrollToEventOrField(event) {
  setTimeout(() => {
    const eventElement = document.getElementById(`event-${event.id}`);
    if (eventElement) {
      return eventElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    const fieldElement = document.getElementById(`field-${event.fieldId}`);
    return fieldElement!.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, 500);
}

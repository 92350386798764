import { UserService } from '../../service/common/user.service';
import AgoToast from '../../components/Toast/AgoToast';

export const getUserPartnerContracts: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_USER_PARTNER_CONTRACTS_REQUESTED',
  });
  return UserService.getContractsFromPartner()
    .then((contracts: any) => {
      dispatch({
        type: 'GET_USER_PARTNER_CONTRACTS_SUCCEEDED',
        payload: contracts,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_USER_PARTNER_CONTRACTS_ERROR',
        payload: err,
      });
    });
};

export const getUserListAction: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_USER_LIST_REQUESTED',
  });
  return UserService.getUsersFromAuth()
    .then((users: any) => {
      dispatch({
        type: 'GET_USER_LIST_SUCCEEDED',
        payload: users,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_USER_LIST_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'User error',
        message: `You don't have permission to access this resource`,
        type: 'error',
        toastId: 'access-token-error',
      });
    });
};

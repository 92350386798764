import { Box } from 'grommet';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMapContext } from '../../service/map/MapContext';
import Field from '../../types/Field';
// @ts-ignore
import { navigateBackToProfile } from '../../service/common/utils';
import { FieldStep } from '../Field/FieldStep';
import { HerdStep } from '../Herd/HerdStep';
import DateRangeModal from '../Modals/DateRangeModal/DateRangeModal';
import ShowMeHowModal from '../Modals/ShowMeHowModal';
import { PaddockStep } from '../Paddock/PaddockStep';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import WizardHeader from './components/WizardHeader';
import SaveAndLeaveModal from '../Modals/SaveAndLeaveModal';
import { Step } from '../../types/Step';
import NextStepButton from './components/NextStepButton';
import FieldStepButtons from './components/FieldStepButtons';
import { EnumProcessingStatus } from '../../service/common/types';

export const Wizard = (): JSX.Element => {
  const { t } = useTranslation();
  const { journeyId } = useParams();
  const { brFinalizeBoundaries, brBoundaryApproval, enableAsyncFieldProcessing } = useFlags();
  const navigate = useNavigate();
  const {
    journey,
    country,
    state,
    fields,
    setWizardStep,
    saveFields,
    upsertShapefile,
    producerData,
    shapefileStatus,
    notifyUpdate,
    willLoseProgressOnExit,
    updatedFieldBoundary,
    processingStatus,
    ineligibleAreasFinalized,
  } = useMapContext();
  const [stepIndex, setStepIndex] = useState<number>(state === 'SECOND_SETUP' ? 1 : 0);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [saveLeaveLoading, setSaveLeaveLoading] = useState<boolean>(false);
  const [showDateRangeModal, setShowDateRangeModal] = useState<boolean>(false);
  const [showMeHowModal, setShowMeHowModal] = useState<boolean>(false);
  const [showSaveAndLeaveModal, setShowSaveAndLeaveModal] = useState<boolean>(false);
  const [field, setField] = useState<Field | undefined>(
    fields.find((field) => field.properties && (field.properties['editing'] || field.properties['selected'])),
  );

  const isChannelPartner = useSelector((state: any) => {
    return !!state.user.userData.channelPartnerId;
  });
  const user = useSelector((state: any) => state.user.userData);

  const steps: Step[] = [
    {
      name:
        country === 'us' && enableAsyncFieldProcessing && !ineligibleAreasFinalized
          ? processingStatus !== EnumProcessingStatus.PROCESSED
            ? 'FieldsProcess'
            : processingStatus === EnumProcessingStatus.PROCESSED
            ? 'FinalizeFieldsProcess'
            : 'Fields'
          : 'Fields',
      title: t('fieldFlow.mainWizard.Fields.title'),
      levels: ['Farm'],
      component: <FieldStep t={t} />,
      ignoreStep: state === 'SECOND_SETUP',
      hideButton:
        isChannelPartner ||
        (country === 'br' && (!brFinalizeBoundaries || 'Data Entry' !== producerData.caseStatus)) ||
        (country !== 'br' && !['Stratification & Data Collection', 'Document & Data Collection'].includes(producerData.caseStatus)),
      hideBorder: true,
    },
    {
      name: 'Paddocks',
      title: t('fieldFlow.mainWizard.Paddocks.title'),
      levels: ['Field'],
      component: <PaddockStep setShowMeHow={setShowMeHowModal} selectedField={field} />,
      hideBorder: true,
    },
    {
      name: 'Herds',
      title: t('fieldFlow.mainWizard.Herds.title'),
      levels: ['Paddock'],
      component: <HerdStep />,
    },
  ];
  const currentStep = steps[stepIndex];
  const win: any = window;
  win.steps = steps;

  useEffect(() => {
    if (state === 'SECOND_SETUP') {
      navigate(`/v2/fields/${journeyId}/overview`);
      setStepIndex(1);
      setWizardStep('PADDOCK');
      setShowDateRangeModal(true);
    }
  }, [state]);

  useEffect(() => {
    let field = fields.find((field) => field.properties && field.properties['editing']);

    if (!field) {
      field = fields.find((field) => field.properties && field.properties['selected']);
    }

    setField(field);
  }, [fields, setField]);

  function shouldNotifyUpdate() {
    if (brBoundaryApproval) {
      return updatedFieldBoundary.current && country === 'br' && (shapefileStatus === 'ANALYSIS_DONE' || shapefileStatus === 'APPROVED');
    }
    return willLoseProgressOnExit && country === 'br' && producerData.attributes.type === 'Opportunity' && producerData['StageName'] !== 'Closed Won';
  }

  function saveAndLeave() {
    if (!journeyId || !journey) return;

    setSaveLeaveLoading(true);
    //Store notify status before save
    const shouldNotify = shouldNotifyUpdate();
    const promises: Promise<void | AxiosResponse>[] = [saveFields()];
    if (shapefileStatus !== 'GATHERING' && updatedFieldBoundary.current) {
      promises.push(upsertShapefile('GATHERING'));
    }
    Promise.all(promises).then(() => {
      if (shouldNotify) {
        return notifyUpdate(producerData['Name'], `${process.env['REACT_APP_PLATFORM_BASE_URL']}/leads/${journeyId}`, user).then(() => {
          navigateBackToProfile(journeyId, navigate);
        });
      }
      return navigateBackToProfile(journeyId, navigate);
    });
  }

  return (
    <>
      {showMeHowModal && <ShowMeHowModal setShowMeHow={setShowMeHowModal} />}
      {showDateRangeModal && (
        <DateRangeModal
          steps={steps}
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          setDateRangeModal={setShowDateRangeModal}
          setShowMeHowModal={setShowMeHowModal}
        />
      )}
      {showSaveAndLeaveModal && <SaveAndLeaveModal setShowSaveAndLeaveModal={setShowSaveAndLeaveModal} saveAndLeave={saveAndLeave} />}
      <Box
        width={'40vw'}
        background={'white'}
        justify={'between'}
        style={{
          position: 'absolute',
          right: '24px',
          top: '24px',
          bottom: '24px',
          zIndex: 1,
          padding: '24px',
          borderRadius: '5px',
          minWidth: '560px',
          maxWidth: '800px',
        }}
      >
        {/*Deactivating breadcrumb, flow currently not working */}
        {/*<BreadCrumb steps={steps} stepIndex={stepIndex} />*/}
        <Box height={'100%'}>
          <WizardHeader currentStep={currentStep} />
          <Box
            margin={currentStep.hideBorder ? '0' : '0 0 0.5rem 0'}
            height={'100%'}
            style={currentStep.hideBorder ? undefined : { borderRadius: '10px' }}
            border={currentStep.hideBorder ? undefined : { color: '#808080', style: 'solid', size: '1px' }}
          >
            {currentStep.component}
          </Box>
          <Box direction={'row'} justify={'between'} width={'100%'}>
            <Box direction='row' justify={'between'} gap={'8px'} width={currentStep.hideButton ? '100%' : undefined}>
              {stepIndex === 0 ? (
                <FieldStepButtons
                  setShowSaveAndLeaveModal={setShowSaveAndLeaveModal}
                  saveAndLeave={saveAndLeave}
                  saveLeaveLoading={saveLeaveLoading}
                  showLoading={showLoading}
                  shouldNotifyUpdate={shouldNotifyUpdate}
                />
              ) : (
                <Box />
              )}
            </Box>
            <NextStepButton
              currentStep={currentStep}
              stepIndex={stepIndex}
              showLoading={showLoading}
              setShowDateRangeModal={setShowDateRangeModal}
              setStepIndex={setStepIndex}
              steps={steps}
              setShowLoading={setShowLoading}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Wizard;

import { Box, MaskedInput, Text } from 'grommet';
import cardCheckbox from '../../../../../assets/cardCheckbox.svg';
import { useResponsiveWrapper } from '../ResponsiveContextWrapper';
import { Scenario } from '../../../../../service/estimate/interfaces';
import { useState } from 'react';

export interface BrazilCoverAnnualFormProps {
  showTitle?: boolean;
  field?: Scenario | null;
  onCheckboxClick?: () => void;
  practice: any;
  labelMargin?: string;
  onEqCarbonChange: (eqCarbon) => void;
}

export const coverAnnualDefaultValue = 1.7;

const BrazilCoverAnnualForm = ({
  showTitle = true,
  field,
  onCheckboxClick,
  practice,
  labelMargin = '1rem 0 0 0',
  onEqCarbonChange,
}: BrazilCoverAnnualFormProps): JSX.Element => {
  const { fontSize } = useResponsiveWrapper();
  const [maskedValue, setMaskedValue] = useState<string>(
    practice?.details?.eqCarbon ? practice?.details?.eqCarbon.toString() : coverAnnualDefaultValue,
  );

  return (
    <Box width={'100%'}>
      {showTitle ? (
        <Box direction={'row'} align={'center'} alignSelf={'center'} justify={'between'} width={'100%'}>
          <Text weight={800} size={fontSize.medium} textAlign={'center'} alignSelf={'center'}>
            Cobertura - Anuais
          </Text>
          <img
            src={cardCheckbox}
            alt={'checkbox'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (onCheckboxClick) onCheckboxClick();
            }}
          />
        </Box>
      ) : (
        <></>
      )}
      <Box style={{ alignItems: 'start', width: '100%' }} margin={labelMargin}>
        <Text size={'16px'} margin={'0 0.5rem 0 0'} alignSelf={'start'} style={{ whiteSpace: 'nowrap' }}>
          Fator (t CO2eq/ha·ano)
        </Text>
        <Box
          background={'#fff'}
          border={{ color: '#808080', size: '1px' }}
          style={{ minHeight: '34px', height: '34px' }}
          direction='row'
          width={'100%'}
          align='center'
        >
          <MaskedInput
            mask={[{ regexp: /^[0-9]*\.?[0-9]*$/ }]}
            style={{ fontWeight: 400 }}
            value={maskedValue}
            plain
            width={'100%'}
            disabled={true}
            data-cy={`cover-crop-annual-carbon-${field?.name}`}
            focusIndicator={false}
            onChange={(event) => {
              if (event.target.value) {
                setMaskedValue(event.target.value);
                const newArea = parseFloat(event.target.value);
                onEqCarbonChange(isNaN(newArea) ? 0 : newArea);
              } else {
                setMaskedValue('');
                onEqCarbonChange(0);
              }
            }}
            placeholder='ex. 1.7'
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BrazilCoverAnnualForm;

import { createContext } from 'react';
import { User } from '../../redux/reducers/user-reducer';

export interface ICognitoContext {
  login: () => void;
  logout: () => void;
  getTokensOrLogin: () => Promise<{ token: string; user: User }>;
  getLoggedUserFromCognito: () => void;
  refreshSessionSilently: () => void;
  changeUserCountry: () => Promise<User>;
}

const notProvided = (): never => {
  throw new Error('Function not provided on context wrapper');
};

const initialContext: ICognitoContext = {
  login: notProvided,
  logout: notProvided,
  getTokensOrLogin: notProvided,
  getLoggedUserFromCognito: notProvided,
  refreshSessionSilently: notProvided,
  changeUserCountry: notProvided,
};

const CognitoContext = createContext<ICognitoContext>(initialContext);

export default CognitoContext;

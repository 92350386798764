import React, { createContext, useContext, useRef, useState } from 'react';
import { initialize, LDClient } from 'launchdarkly-react-client-sdk';
import { User } from 'auth0';
import _ from 'lodash';

interface AdminContextType {
  adminPortalLD: LDClient;
}

const AdminLDContext = createContext<Record<string, any> & AdminContextType>({} as Record<string, any> & AdminContextType);
export const useAdminLD = () => useContext(AdminLDContext);

export function AdminPortalLDContextProvider({ children, user }: { children: React.ReactNode; user: (Record<string, any> & User) | undefined }) {
  const [adminFlags, setAdminFlags] = useState<Record<string, boolean>>({});
  const adminPortalLD = useRef(
    initialize('627bc763a789b115b4b3ad16', {
      kind: 'user',
      key: user?.sub || 'anonymous-user',
      email: user?.email,
      name: user?.name,
    }),
  );

  function convertKeysToCamelCase(object: Record<string, any>) {
    return _.mapKeys(object, (v, k) => _.camelCase(k));
  }

  adminPortalLD.current.on('ready', () => {
    setAdminFlags(convertKeysToCamelCase(adminPortalLD.current.allFlags()));
  });

  adminPortalLD.current.on('change', () => {
    setAdminFlags(convertKeysToCamelCase(adminPortalLD.current.allFlags()));
  });

  return <AdminLDContext.Provider value={{ adminPortalLD: adminPortalLD.current, ...adminFlags }}>{children}</AdminLDContext.Provider>;
}

import { MutableRefObject, ReactNode, useCallback, useMemo, useRef } from 'react';
import CognitoContext from './CognitoContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  CognitoIdentityProviderClient,
  GetUserCommand,
  InitiateAuthCommand,
  UpdateUserAttributesCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import axios from 'axios';
import { User } from '../../redux/reducers/user-reducer';
import { GATEWAY_URL } from '../../service/common/env';

export interface ICognitoProviderProps {
  children?: ReactNode;
}

const cognitoClient = new CognitoIdentityProviderClient({
  region: 'us-east-2',
});

const CognitoProvider = ({ children }: ICognitoProviderProps): JSX.Element => {
  const navigate = useNavigate();

  const accessToken: MutableRefObject<string | null> = useRef(localStorage.getItem('accessToken'));
  const awsToken: MutableRefObject<string | null> = useRef(localStorage.getItem('awsToken'));
  const refreshToken: MutableRefObject<string | null> = useRef(localStorage.getItem('refreshToken'));
  const [searchParams] = useSearchParams();
  const queryCode = searchParams.get('code');

  const loggedInUser: MutableRefObject<User | undefined> = useRef(undefined);

  const login = useCallback(() => {
    const redirectLocation = window.location.pathname + (window.location.search ? window.location.search : '');
    localStorage.setItem('redirectLocation', redirectLocation);

    const cognitoHostedUiUrl = process.env.REACT_APP_COGNITO_HOSTED_UI_URL;
    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const redirectUrl = process.env.REACT_APP_STATIC_FILES_ENDPOINT;
    window.location.href = `${cognitoHostedUiUrl}/login?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${redirectUrl}/`;
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('redirectLocation');

    loggedInUser.current = undefined;

    const cognitoHostedUiUrl = process.env.REACT_APP_COGNITO_HOSTED_UI_URL;
    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const redirectUrl = process.env.REACT_APP_STATIC_FILES_ENDPOINT;
    window.location.href = `${cognitoHostedUiUrl}/logout?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${redirectUrl}/`;
  }, []);

  const refreshSessionSilently = useCallback(
    async (refreshTokenParam?: string): Promise<{ accessToken: string; awsToken: string }> => {
      if (!refreshToken.current && !refreshTokenParam) {
        login();
        throw new Error('Cant refresh session - no refresh token found');
      }

      const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
      const command = new InitiateAuthCommand({
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        AuthParameters: {
          REFRESH_TOKEN: refreshTokenParam || refreshToken.current!,
        },
        ClientId: clientId,
      });

      const tokens = await cognitoClient.send(command);
      if (!tokens?.AuthenticationResult?.IdToken) {
        login();
        throw new Error('Cant refresh session - no token on response');
      }

      const newAccessToken = tokens.AuthenticationResult.IdToken;
      const awsToken = tokens.AuthenticationResult.AccessToken || '';
      localStorage.setItem('accessToken', newAccessToken);
      localStorage.setItem('awsToken', awsToken);

      return { accessToken: newAccessToken, awsToken };
    },
    [login],
  );

  const getLoggedUserFromCognito = useCallback(
    async (userAccessToken?: string): Promise<User> => {
      const command = new GetUserCommand({
        AccessToken: userAccessToken ?? (awsToken.current as string),
      });
      const user = await cognitoClient.send(command);
      const attributes = Object.fromEntries(
        user.UserAttributes?.map((attr) => {
          return [attr.Name, attr.Value];
        }) ?? [],
      );

      const envPrefix = {
        stage: 'stage',
        staging: 'stage',
        preprod: 'preprod',
        production: 'prod',
        prod: 'prod',
      };

      return {
        ...attributes,
        username: user.Username,
        // @ts-ignore
        id: 'samlp|gw-oat-enterprise-' + envPrefix[process.env.REACT_APP_ENVIRONMENT ?? 'staging'] + '|' + attributes.email,
        email: attributes.email,
        channelPartnerId: attributes['custom:channelPartnerId'] || '',
        partnerType: attributes['custom:partnerType'],
        partnerTier: attributes['custom:partnerTier'] || null,
        country: (attributes['custom:country'] || 'US') as 'US' | 'BR',
        language: attributes['custom:country'] === 'BR' ? 'pt-BR' : 'en',
        fullName: `${attributes.given_name} ${attributes.family_name}`,
        firstName: attributes.given_name,
        lastName: attributes.family_name,
        // TODO: check if this exists
        phoneNumber: attributes.phoneNumber || '',
        // TODO: get roles correctly
        roles: [],
      };
    },
    [awsToken],
  );

  const getTokensOrLogin = useCallback(async (): Promise<{
    token: string;
    user: User;
  }> => {
    if (!queryCode && !accessToken) login();
    if (queryCode) {
      const tokens = await getTokensFromLambda(queryCode);

      localStorage.setItem('accessToken', tokens.accessToken);
      localStorage.setItem('refreshToken', tokens.refreshToken);
      localStorage.setItem('awsToken', tokens.awsToken);
      navigate(localStorage.getItem('redirectLocation') as string);

      accessToken.current = tokens.accessToken;
      refreshToken.current = tokens.refreshToken;
      awsToken.current = tokens.awsToken;

      const user = await getLoggedUserFromCognito(tokens.awsToken);
      loggedInUser.current = user;

      return { token: tokens.accessToken, user };
    }

    let tokens = {
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
      awsToken: localStorage.getItem('awsToken') ?? undefined,
    };

    let user;
    try {
      user = await getLoggedUserFromCognito(tokens.awsToken);
    } catch (e) {
      const newTokens = await refreshSessionSilently(tokens.refreshToken || undefined);
      tokens = {
        ...tokens,
        ...newTokens,
      };
      user = await getLoggedUserFromCognito(newTokens.awsToken);
    } finally {
      // In case the refresh token does not work, we must log in again
      if (!user) {
        login();
      }
    }
    loggedInUser.current = user;

    return { token: tokens.accessToken as string, user };
  }, [queryCode, login, navigate, getLoggedUserFromCognito, refreshSessionSilently]);

  const getTokensFromLambda = async (
    cognitoCode: string,
  ): Promise<{
    awsToken: string;
    accessToken: string;
    refreshToken: string;
  }> => {
    const tokenGenEndpoint = `${GATEWAY_URL}/agoro-token-gen`;
    const redirectUrl = process.env.REACT_APP_STATIC_FILES_ENDPOINT;

    const res = await axios.post(tokenGenEndpoint, {
      code: cognitoCode,
      redirect_uri: `${redirectUrl}/`,
    });
    return {
      accessToken: res.data.id_token,
      awsToken: res.data.access_token,
      refreshToken: res.data.refresh_token,
    };
  };

  const changeUserCountry = useCallback(
    async (userAccessToken?: string): Promise<User> => {
      const newCountry: 'US' | 'BR' = loggedInUser.current?.country === 'BR' ? 'US' : 'BR';
      const input = {
        UserAttributes: [
          {
            Name: 'custom:country',
            Value: newCountry,
          },
        ],
        AccessToken: userAccessToken ?? (awsToken.current as string),
      };
      const command = new UpdateUserAttributesCommand(input);
      await cognitoClient.send(command);
      const newUser = {
        ...loggedInUser.current!,
        country: newCountry,
      };
      loggedInUser.current = newUser;

      await refreshSessionSilently();
      window.location.reload();
      return newUser;
    },
    [refreshSessionSilently],
  );

  const context = CognitoContext;
  const contextValue = useMemo(() => {
    return {
      login,
      logout,
      getTokensOrLogin,
      getLoggedUserFromCognito,
      refreshSessionSilently,
      changeUserCountry,
    };
  }, [login, logout, getTokensOrLogin, getLoggedUserFromCognito, refreshSessionSilently, changeUserCountry]);

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default CognitoProvider;

import reducedTillageIcon from '../../../../assets/BoundaryTool/practices/reducedTillageIcon.svg';
import coverCropIcon from '../../../../assets/BoundaryTool/practices/coverCropIcon.svg';
import fertilizationIcon from '../../../../assets/BoundaryTool/practices/fertilization.svg';
import iclIcon from '../../../../assets/BoundaryTool/practices/icl.svg';
import improvedGrazingIcon from '../../../../assets/BoundaryTool/practices/improvedGrazing.svg';
import seedingIcon from '../../../../assets/BoundaryTool/practices/seeding.svg';
import legumeIcon from '../../../../assets/BoundaryTool/practices/legume.svg';
import i18next, { TFunction } from 'i18next';

export const monthList = () => {
  return i18next.language === 'en'
    ? ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    : ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
};

export const getIconDictionary = (t: TFunction) => {
  return {
    reducedTillage: { icon: reducedTillageIcon, tooltip: t('fieldFlow.fieldItem.practices.options.reducedTillage') },
    noTillage: { icon: reducedTillageIcon, tooltip: t('fieldFlow.fieldItem.practices.options.noTillage') },
    coverCropping: { icon: coverCropIcon, tooltip: t('fieldFlow.fieldItem.practices.options.coverCropping') },
    nitrogenReduction: { icon: fertilizationIcon, tooltip: t('fieldFlow.fieldItem.practices.options.nitrogenReduction') },
    nitrogenOptimization: { icon: fertilizationIcon, tooltip: t('fieldFlow.fieldItem.practices.options.nitrogenOptimization') },
    legume: { icon: legumeIcon, tooltip: t('fieldFlow.fieldItem.practices.options.legume') },
    fertilization: { icon: fertilizationIcon, tooltip: t('fieldFlow.fieldItem.practices.options.fertilization') },
    grazing: { icon: improvedGrazingIcon, tooltip: t('fieldFlow.fieldItem.practices.options.grazing') },
    seeding: { icon: seedingIcon, tooltip: t('fieldFlow.fieldItem.practices.options.seeding') },
    ICL: { icon: iclIcon, tooltip: t('fieldFlow.fieldItem.practices.options.ICL') },
    coverAnnual: { icon: iclIcon, tooltip: t('fieldFlow.fieldItem.practices.options.coverAnnual') },
    coverPerennial: { icon: iclIcon, tooltip: t('fieldFlow.fieldItem.practices.options.coverPerennial') },
    inoculant: { icon: iclIcon, tooltip: t('fieldFlow.fieldItem.practices.options.inoculat') },
  };
};

export const SF_PRACTICES_MAP = {
  RPBS: 'seeding',
  RPF: 'fertilization',
  RPIG: 'grazing',
  NT: 'noTillage',
  NO: 'nitrogenOptimization', // legacy support no longer use this status
  NRR: 'nitrogenReduction',
  CC: 'coverCropping',
  LA: 'legume',
  IT: 'reducedTillage',
  SPA: 'singlePracticeIncentive',
  DPA: 'dualPracticeIncentive',
  TPA: 'triplePracticeIncentive',
  ICL1: 'ICL',
  ICL2: 'ICL',
};

export const kmToAcre = 0.00024710538146717;
export const kmToHectare = 0.0001;

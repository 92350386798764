import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.css';
import { Grommet } from 'grommet';
import { Provider } from 'react-redux';
import store from './redux';
import reportWebVitals from './reportWebVitals';
import { defaultTheme } from './theme';
import { BrowserRouter } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ToastContainer } from 'react-toastify';
import { AdminPortalLDContextProvider } from './providers/AdminContext';
import CognitoProvider from './components/Cognito/CognitoProvider';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_RELEASE_VERSION,
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const AppWithLaunchDarkly = () => {
  const { user } = useAuth0();
  const LDProviderWrappedApp = withLDProvider({
    clientSideID: String(process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID),
    context: {
      key: user?.sub || 'anonymous-user',
      email: user?.email,
      name: user?.name,
    },
  })(App);
  return (
    <AdminPortalLDContextProvider user={user}>
      <LDProviderWrappedApp />
    </AdminPortalLDContextProvider>
  );
};

root.render(
  <React.StrictMode>
    <Grommet theme={defaultTheme}>
      <ToastContainer />
      <Provider store={store}>
        <BrowserRouter>
          <CognitoProvider>
            <AppWithLaunchDarkly />
          </CognitoProvider>
        </BrowserRouter>
      </Provider>
    </Grommet>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

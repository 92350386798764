import { useEffect, useState } from 'react';
import { Feature } from '@turf/helpers';
import mapboxgl from 'mapbox-gl';
import { MarkerHelper } from './map/MarkerHelper';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { StructureType } from '../components/Structure/StructureItem';
import { useTranslation } from 'react-i18next';
import { getRandomNumber } from './common/utils';
import { states } from './map/MapContext';
import { Journey } from '../types/Journey';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { API } from '../../../service/common/api';
import { FIELD_URL } from '../../../service/common/env';

export const useStructureService = (
  paddocks: { [key: string]: Feature[] },
  state: states,
  setState: (state: states) => void,
  startDate?: Date,
  endDate?: Date,
  map?: mapboxgl.Map,
  journey?: Journey,
) => {
  const { hideStructuresAndEvents } = useFlags();
  const [searchParams] = useSearchParams();
  const fieldId = searchParams.get('fieldId');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { journeyId } = useParams();
  const [structures, setStructures] = useState<any[]>([]);
  const [structuresInvalid, setStructuresInvalid] = useState<boolean>(false);

  function handleStructureMarkers(structure, newPaddocks?: Record<string, any>) {
    if (!map) {
      return;
    }

    return MarkerHelper.generateNewMarkerOnMap(
      structure,
      newPaddocks ?? paddocks,
      map,
      'STRUCTURE',
      () => {
        if (state === 'OVERVIEW' || state === 'DETAILS') {
          setState('DETAILS');
          navigate(`/v2/fields/${journeyId}/details?fieldId=${structure.fieldId}&elementId=${structure.id}&type=STRUCTURE`);
        }
      },
      state === 'OVERVIEW' || state === 'DETAILS' ? 'pointer' : 'default',
      undefined,
      updateStructure,
      postStructure,
    );
  }

  function updateStructureData(updatedStructureData, newPaddocks?: Record<string, any>) {
    if (!map) {
      return;
    }

    const treatedStructures = updatedStructureData.map((herd) => handleStructureMarkers(herd, newPaddocks));
    updatedStructureData.forEach((event, index) => {
      if (treatedStructures[index]?.marker) updatedStructureData[index].marker = treatedStructures[index]?.marker;
    });
    filterStructureMarkers(updatedStructureData);
    setStructures(updatedStructureData);
  }

  function filterStructureMarkers(structureData?: any[]) {
    if (!structureData) structureData = structures;

    for (const structure of structureData) {
      if (structure.marker) {
        if (!fieldId) {
          structure.marker.addTo(map);
          continue;
        }

        if (structure.marker && structure.fieldId !== fieldId) {
          structure.marker.remove();
        } else {
          structure.marker.addTo(map);
        }
      }
    }
  }

  function removeAllMarkers() {
    for (const structure of structures) {
      if (structure.marker) structure.marker.remove();
    }
  }

  useEffect(() => {
    if (state === 'SECOND_SETUP') removeAllMarkers();
    filterStructureMarkers(structures);
  }, [fieldId, state]);

  const getStructures = (fieldId?: string, newPaddocks?: { [key: string]: Feature[] }) => {
    if (hideStructuresAndEvents) return;

    const params: any = { journeyId: journey?.id };
    if (fieldId) params.fieldId = fieldId;
    return API.post(`${FIELD_URL}/field-service/structures/fetch`, params)
      .then(async (structureResults) => {
        await clearStructures();

        // TODO: fix API to work with dateRange
        const treatedStructures = structureResults.data
          .map((structure: any) => {
            return {
              ...structure,
              startDate: structure.startDate ? new Date(structure.startDate) : undefined,
              endDate: structure.endDate ? new Date(structure.endDate) : undefined,
              dateIn: structure.dateIn ? new Date(structure.dateIn) : undefined,
            };
          })
          .filter((structure: any) => {
            const startDateValidation = startDate ? structure.dateIn >= new Date(startDate) : true;
            const endDateValidation = endDate ? structure.dateIn <= new Date(endDate) : true;
            return startDateValidation && endDateValidation;
          });
        updateStructureData(treatedStructures, newPaddocks);
      })
      .catch(console.log);
  };

  function updateStructure(structure: any) {
    setStructures((structures) => {
      const index = structures.findIndex((structureSearch) => structureSearch.id === structure.id);
      structures[index] = {
        ...structure,
        invalid: isStructureInvalid(structure),
      };
      return [...structures];
    });
  }

  function addStructure(fieldId?: string) {
    let paddockId: any = undefined;
    if (fieldId && paddocks[fieldId].length === 1) paddockId = paddocks[fieldId][0].id;

    const treatedStructure = handleStructureMarkers({
      id: getRandomNumber(),
      name: `${t('fieldFlow.waterItem.waterSource')} #${structures.length + 1}`,
      type: StructureType.WATER_SOURCE,
      subtype: '',
      dateIn: startDate,
      fieldId,
      paddockId,
      invalid: true,
    });
    setStructures([...structures, treatedStructure]);
    filterStructureMarkers([treatedStructure]);
  }

  function removeStructure(structure: any) {
    setStructures((structures) => {
      if (structure.marker) {
        structure.marker.remove();
      }

      return structures.filter((structureSearch) => structureSearch.id !== structure.id);
    });
  }

  async function clearStructures() {
    for (const structure of structures) {
      if (structure.marker) structure.marker.remove();
    }

    setStructures([]);
  }

  function saveStructure(structure: any) {
    return postStructure(structure).then((response) => {
      const index = structures.findIndex((structureSearch: any) => structureSearch.id === structure.id);
      const newStructure = response.data[0];
      setStructures((structures) => {
        structures[index].marker?.remove();
        structures[index] = handleStructureMarkers({
          ...newStructure,
          startDate: newStructure.startDate ? new Date(newStructure.startDate) : undefined,
          endDate: newStructure.endDate ? new Date(newStructure.endDate) : undefined,
          dateIn: newStructure.dateIn ? new Date(newStructure.dateIn) : undefined,
        });
        filterStructureMarkers([structures[index]]);
        return [...structures];
      });
    });
  }

  function postStructure(structure: any): Promise<any> {
    const body = [
      {
        ...structure,
        growerId: journey?.leadId ?? journey?.opportunityId,
        journeyId: journey?.id,
        startDate: startDate || new Date(),
        endDate: endDate,
        marker: undefined,
        invalid: undefined,
        id: typeof structure.id === 'number' ? undefined : structure.id,
      },
    ];

    return API.post(`${FIELD_URL}/field-service/structures`, body);
  }

  function saveStructures(): Promise<any> {
    const body = structures.map((structure) => {
      return {
        ...structure,
        growerId: journey?.leadId ?? journey?.opportunityId,
        journeyId: journey?.id,
        startDate: startDate || new Date(),
        endDate: endDate,
        marker: undefined,
        invalid: undefined,
        id: typeof structure.id === 'number' ? undefined : structure.id,
      };
    });

    return API.post(`${FIELD_URL}/field-service/structures`, body).then((res) => {
      clearStructures().then(() => {
        updateStructureData(res.data);
      });
    });
  }

  useEffect(() => {
    setStructuresInvalid(structures.some(isStructureInvalid));
  }, [structures]);

  function isStructureInvalid(structure: any) {
    return !structure.name || !structure.subtype || !structure.dateIn || !structure.fieldId || !structure.paddockId;
  }

  return {
    getStructures,
    clearStructures,

    structures,
    structuresInvalid,
    setStructures,
    addStructure,
    updateStructure,
    removeStructure,
    saveStructure,
    saveStructures,
  };
};

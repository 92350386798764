import {
  EventTypes,
  IDCAnimalDataEvent,
  IDCCoverCroppingEvent,
  IDCFertilizerEvent,
  IDCHarvestEvent,
  IDCPlantingEvent,
  IDCTillageEvent,
  IEvents,
} from '../interfaces';
import { v4 as uuidv4 } from 'uuid';
import { Box, Text } from 'grommet';

export function createNewEvent(fieldId: string, eventType: EventTypes, additionalInfo?: any): any {
  const defaultEvents = {
    Tillage: {
      id: uuidv4(),
      tillageDate: '',
      tillageTypeId: '',
      fieldId,
      newEvent: true,
    },
    Planting: {
      id: uuidv4(),
      plantingDate: '',
      plantSpeciesId: '',
      fieldId,
      newEvent: true,
    },
    Harvest: {
      id: uuidv4(),
      plantingId: additionalInfo?.plantingId,
      harvestDate: '',
      residueRemoval: additionalInfo?.residueRemoval,
      harvestMethodId: additionalInfo?.harvestMethodId,
      yield: '',
      // yieldUsedOnFarm: '',
      yieldUnitId: additionalInfo?.yieldUnitId,
      fieldId,
      newEvent: true,
    },
    CoverCrop: {
      id: uuidv4(),
      terminationMethodId: '',
      plantingDate: '',
      terminationDate: '',
      interseeding: false,
      species: [],
      modelInputId: '',
      fieldId,
      newEvent: true,
    },
    Fertilization: {
      id: uuidv4(),
      fertilizerTypeId: additionalInfo?.fertilizerTypeId ?? null,
      dateApplied: '',
      amount: '',
      amountUnitId: '',
      organicSourceId: additionalInfo?.organicSourceId ?? null,
      fieldId: fieldId,
      newEvent: true,
      appliedAcreage: additionalInfo?.initialAppliedAcreage,
    },
    Seeding: {
      id: uuidv4(),
      dateOfApplication: '',
      plantSpeciesId: '',
      speciesModelInputId: '',
      rate: '',
      rateUnitId: '',
      seedingMethodId: '',
      appliedAcreage: additionalInfo?.initialAppliedAcreage,
      notes: '',
      fieldId,
      newEvent: true,
    },
    FieldData: {
      id: uuidv4(),
      year: '',
      dominantSpecies: [],
      speciesModelInputId: '',
      paddockNum: 1,
      averagePaddockSize: additionalInfo?.fieldArea?.toFixed(2),
      notes: '',
      fieldId,
      newEvent: true,
    },
    AnimalData: {
      id: uuidv4(),
      year: '',
      herdName: '',
      operationTypeId: '',
      animalTypeId: '',
      averageWeight: '',
      headCount: '',
      notes: '',
      fieldId,
      newEvent: true,
    },
  };

  return defaultEvents[eventType];
}

export function getDateText(dateStr: string | Date): string {
  if (!dateStr) return '';
  const date: Date = typeof dateStr === 'string' ? new Date(dateStr) : dateStr;
  return date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  });
}

export function getUnitName(unit: string) {
  return unit.includes('/Acre') ? unit.replace('/Acre', ' per acre') : unit.concat(' per acre');
}

export function eventsModified(eventUpdated, eventFull, typeAttributes: string[]) {
  return typeAttributes.some((attribute) => {
    if (eventUpdated[attribute] && eventFull[attribute] && Array.isArray(eventUpdated[attribute])) {
      return eventUpdated[attribute].join() !== eventFull[attribute]?.join();
    }
    return eventUpdated[attribute]?.toString()?.split('T')?.[0] !== eventFull[attribute]?.toString()?.split('T')?.[0];
  });
}

export function calculateInitialAppliedAcreage(fieldEvents: any[], fieldAcreage: number) {
  let usedAcreage = fieldEvents.reduce((acc, ev) => acc + Number(Number(ev.appliedAcreage).toFixed(2)), 0);
  const finalValue = (fieldAcreage - usedAcreage).toFixed(2);
  return Number(finalValue) > 0 ? finalValue : 0;
}

export const ErroredEventMapping: { [key in EventTypes]: (event: IEvents, otherEvent?: IDCPlantingEvent | IDCHarvestEvent[]) => boolean } = {
  Tillage: (event) => {
    const typedEvent = event as IDCTillageEvent;
    return !!typedEvent.missingData || !!typedEvent.duplicatedDate;
  },
  CoverCrop: (event) => {
    const typedEvent = event as IDCCoverCroppingEvent;
    return !!typedEvent.missingData || !!typedEvent.duplicatedDate || !!typedEvent.dateConflict || !!typedEvent.phEventOverlap;
  },
  Planting: (event, harvestEvents) => {
    const typedEvent = event as IDCPlantingEvent;
    const issuesWithPlanting =
      !!typedEvent.missingData ||
      !!typedEvent.duplicatedDate ||
      !!typedEvent.phEventOverlap ||
      !!typedEvent.ccEventOverlap ||
      !!typedEvent.missingHarvestEvent;
    if (!harvestEvents) return issuesWithPlanting;

    const relatedHarvest = (harvestEvents as IDCHarvestEvent[]).filter((harvest) => harvest.plantingId === event.id);
    let multipleHarvest = relatedHarvest.some((harvest) => harvest.duplicatedDate || harvest.missingData);

    return issuesWithPlanting || multipleHarvest;
  },
  Harvest: (event, plantingEvent) => {
    if (!plantingEvent) throw Error('Harvest evaluation needs to receive planting event');
    const typedEvent = event as IDCHarvestEvent;
    return !!typedEvent.missingData || !!typedEvent.duplicatedDate || ErroredEventMapping.Planting(plantingEvent as IDCPlantingEvent);
  },
  Fertilization: (event) => {
    const typedEvent = event as IDCFertilizerEvent;
    return !!typedEvent.missingData || !!typedEvent.duplicatedDate;
  },
  Seeding: (event) => {
    return false;
  },
  FieldData: (event) => {
    return false;
  },
  AnimalData: (event) => {
    const typedEvent = event as IDCAnimalDataEvent;
    return !!typedEvent.missingData || !!typedEvent.duplicatedDate;
  },
};

export const ORGANIC_SOURCE_TOOLTIP = (
  <Box direction={'column'} justify={'center'} margin={'0 0 0.25rem 21.75rem'}>
    <Box
      round={'0.25rem'}
      pad={'0.75rem 0.5rem'}
      height={'fit-content'}
      width={'25rem'}
      gap={'0.75rem'}
      justify={'center'}
      background={{ color: '#494949' }}
    >
      <Text>
        <b>On-site: </b>Manure originates from the farm/ranch.
      </Text>
      <Text>
        <b>Off-site controlled: </b>Imported manure from a managed source (e.g., stored aerobically/anaerobically with methane recovery for energy
        generation).
      </Text>
      <Text>
        <b>Off-site uncontrolled: </b>Imported manure from a managed source (e.g., stored aerobically/anaerobically with methane recovery for energy
        generation).
      </Text>
      <Text>
        <b>Off-site unknown: </b>Origin of manure is unspecified.
      </Text>
    </Box>
    <Box
      margin={'0 0 0 1rem'}
      style={{
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid #494949',
      }}
    />
  </Box>
);

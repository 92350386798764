import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Field, { EnrollmentStatus } from '../types/Field';
import { Feature } from '@turf/helpers';
import * as turf from '@turf/turf';
import { isArray } from 'lodash';
// @ts-ignore
import { MAP_MODES } from '../components/Drawing/modes/modes';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import { FieldLayout } from '../types/FieldLayout';
import DrawControl from '../components/Map/DrawControl';
import { v4 as uuidv4 } from 'uuid';
import { AddressSearchService } from './AddressSearchService';
import { MapUtilities } from './map/MapUtilities';
import { ShapefileStatus } from '../types/Shapefiles';
// @ts-ignore
import { kmToAcre, kmToHectare } from './common/constants';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import i18next from 'i18next';
// @ts-ignore
import { showToast } from './common/utils';
import { polygonArea } from './common/AreaCalc';
import { Journey } from '../types/Journey';
import { EnumProcessingStatus } from './common/types';
import { BASE_URL, CONTRACT_URL, FIELD_URL } from '../../../service/common/env';
import { API } from '../../../service/common/api';
import { User } from '../../../redux/reducers/user-reducer';

const SF_PRACTICES_MAP = {
  seeding: 'RPBS',
  fertilization: 'RPF',
  grazing: 'RPIG',
  noTillage: 'NT',
  nitrogenOptimization: 'NO', // legacy support no longer use this status
  nitrogenReduction: 'NRR',
  coverCropping: 'CC',
  reducedTillage: 'IT',
  legume: 'LA',
  ICL1: 'ICL1',
  ICL2: 'ICL2',
  SPA: 'SPA',
  DPA: 'DPA',
  TPA: 'TPA',
  coverPerennial: 'CP',
  coverAnnual: 'CA',
  inoculant: 'IN',
};

interface FieldServiceProps {
  setMode: (mode: string) => void;
  mode: string;
  shouldZoomFields: MutableRefObject<boolean>;
  draw: DrawControl | null;
  journey?: Journey;
  producerData?: any;
  map?: mapboxgl.Map;
  currentVersion?: FieldLayout;
  setShowCloserFields: (value: ((prevState: boolean) => boolean) | boolean) => void;
  showCloserFields: boolean;
  country: string;
  contractPaymentOption: string | undefined;
}

export interface SyncErrors {
  practices: Array<{ name: string; sf: number; platform: number; option: string }>;
  paymentOptionErrors: Array<{ id: string; name: string; area: number }>;
  missingPractices: string[];
  status: string;
}

// TODO: find a better way to use draw instead of just importing it (map stuff should not be on this service)
export const useFieldService = ({
  setMode,
  mode,
  shouldZoomFields,
  draw,
  map,
  currentVersion,
  journey,
  producerData,
  setShowCloserFields,
  showCloserFields,
  country,
  contractPaymentOption,
}: FieldServiceProps) => {
  const { t } = useTranslation();
  const { journeyId } = useParams();
  const { upsellingBoundaryTool, brBoundaryApproval, removeIneligibleAreasAutomatically, enableAsyncFieldProcessing } = useFlags();

  const [fields, setFields] = useState<Field[]>([]);
  const [paddocks, setPaddocks] = useState<{ [key: string]: Feature[] }>({});
  const [removedFields, setRemovedFields] = useState<Field[]>([]);
  const [fieldsIntersecting, setFieldsIntersecting] = useState<boolean>(false);
  const [fieldsDrawingInvalid, setFieldsDrawingInvalid] = useState<boolean>(true);
  const [paddocksInvalid, setPaddocksInvalid] = useState<boolean>(false);
  const [deleteField, setDeleteField] = useState<Field | undefined>();
  const [deleteAllFields, setDeleteAllFields] = useState<boolean>(false);
  const [lastFieldNumSaved, setLastFieldsNumSaved] = useState<number>(1);
  const [loadingFields, setLoadingFields] = useState(true);
  const [finishedDrawingField, setFinishedDrawingField] = useState<boolean>(true);
  const [finishedDrawingPaddock, setFinishedDrawingPaddock] = useState<boolean>(true);
  const [willLoseProgressOnExit, setWillLoseProgressOnExit] = useState<boolean>(false);
  const [syncErrors, setSyncErrors] = useState<SyncErrors>({ practices: [], paymentOptionErrors: [], missingPractices: [], status: 'UNKOWN' });
  const [practicesOnContract, setPracticesOnContract] = useState<any>([]);
  const [nearFields, setNearFields] = useState<any[]>([]);
  const [overlaps, setOverlaps] = useState<any>(null);

  const loadingFieldNumber = useRef<boolean>(false);
  const updatedFieldBoundary = useRef<boolean>(false);
  const paddocksBeingDrawn = useRef<Feature[]>();

  const [uploadingBoundaries, setUploadingBoundaries] = useState(false);
  const [uploadingDrawnField, setUploadingDrawnField] = useState(false);

  const [processingStatus, setProcessingStatus] = useState<EnumProcessingStatus>(EnumProcessingStatus.NOT_PROCESSED);
  const [ineligibleAreasFinalized, setIneligibleAreasFinalized] = useState<boolean>(false);

  const isChannelPartner = useSelector((state: any) => {
    return !!state.user.userData.channelPartnerId;
  });

  const totalFieldsAcreage = useMemo(() => {
    return fields
      .filter((f: Field) => !f.properties?.unenrolledAt)
      .reduce((totalAcres: number, f) => totalAcres + (f.properties?.[i18next.language === 'pt-BR' ? 'areaHectare' : 'area'] ?? 0), 0)
      .toFixed(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, changeFieldEnrollmentStatus]);

  useEffect(() => {
    if (!journeyId || loadingFieldNumber.current) return;

    loadingFieldNumber.current = true;
    API.get(`${FIELD_URL}/field-service/fields/field-number`, { journeyId })
      .then((res) => {
        loadingFieldNumber.current = false;
        setLastFieldsNumSaved(res.data + 1);
      })
      .catch(() => {
        loadingFieldNumber.current = false;
      });
  }, [journeyId]);

  async function getFields(avoidRedraw?: boolean) {
    try {
      setLoadingFields(true);
      const limit = 70;

      let finalFields: any[] = [];
      let initialLength = 0,
        finalLength = limit;
      let result;

      while (finalLength - initialLength === limit) {
        initialLength = finalFields.length;

        result = await API.get(`${FIELD_URL}/field-service/fields`, {
          journeyId: journey?.id,
          getPaddocks: 'true',
          timestamp: currentVersion ? new Date(currentVersion.startDate) : undefined,
          limit,
          offset: finalFields.length,
        });

        finalFields = finalFields.concat(result.data);
        finalLength = finalFields.length;
      }

      const newPaddocks = setNewFields(
        finalFields.map((field) => {
          // The 'parent' property is used to reference the field for editing
          field.boundaries.properties.parent = field.id;
          return field;
        }),
        avoidRedraw,
      );
      // TODO don't call when this API is not necessary (opening on overview)
      await verifyNearFieldsAndOverlap(finalFields);

      if (enableAsyncFieldProcessing && country === 'us') {
        const hasNotProcessed = finalFields.some((b) => !b.ineligibleAreasProcessed);
        const status = !hasNotProcessed ? EnumProcessingStatus.PROCESSED : EnumProcessingStatus.NOT_PROCESSED;
        setProcessingStatus(status);

        const hasNotFinalized = finalFields.length ? finalFields.some((b) => !b.boundaries?.properties?.ineligibleAreasFinalized) : true;
        setIneligibleAreasFinalized(!hasNotFinalized);
      }

      return { fields: finalFields, paddocks: newPaddocks };
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingFields(false);
    }
  }

  function setNewFields(newFields: any[], avoidRedraw?: boolean) {
    const paddocks: { [key: string]: Feature[] } = {};
    const mappedFields = newFields.map((field) => {
      paddocks[field.id] = [];

      if (field.layout && field.layout.paddocks && field.layout.paddocks.length) {
        paddocks[field.id] = treatFieldPaddockData(field);
      }
      return {
        ...(!enableAsyncFieldProcessing && field.boundaries.properties?.originalBoundary
          ? field.boundaries.properties.originalBoundary
          : field.boundaries),
        id: field.id,
        properties: {
          ...field.boundaries.properties,
          id: undefined,
          updatedAt: field.updatedAt,
          updatedBy: field.updatedBy,
          name: field.name,
          area: field.area,
          areaHectare: field.areaHectare,
          situation: field.situation,
          practiceType: field.practiceType,
          practices: field.fieldPractices.map((practice: any) => practice.metadata.practiceName),
          paymentOption: field.paymentOption,
          county: field.county,
          country: field.country,
          state: field.state,
          irrigated: field.irrigated,
          legume: field.fieldPractices.find((practice: any) => !!practice.metadata.legume)?.metadata.legume === 'legume', // TODO : remove legume from field level and instead base everything off metadata directly
          previousTillage:
            field.fieldPractices.find((practice: any) => !!practice.metadata.previousTillage)?.metadata.previousTillage ??
            field.previousTillage ??
            'intensive', //Set to default in case previousTillage is null
          fieldPractices: field.fieldPractices,
          unenrolledAt: field.unenrolledAt,
        },
      };
    });

    if (!map) shouldZoomFields.current = true;

    getProcessedFieldsBoundaries(mappedFields).then((processedFields) => {
      verifyNearFieldsAndOverlap(mappedFields);
      if (avoidRedraw) {
        setFields((fields) => {
          fields = updateFieldsIntersections(processedFields);
          return fields;
        });
      } else {
        setFields(updateFieldsIntersections(processedFields));
      }
      setFields(() => {
        return updateFieldsIntersections(processedFields);
      });
    });

    setRemovedFields([]);
    setPaddocks(paddocks);
    return paddocks;
  }

  function treatFieldPaddockData(field: any) {
    return [
      ...field.layout.paddocks
        .filter((paddock: any) => !!paddock.boundary && Object.keys(paddock.boundary).length)
        .map((paddock: any) => {
          return {
            ...paddock.boundary,
            id: paddock.id,
            fieldId: field.id,
            layoutId: field.layout.id,
            properties: {
              ...(paddock.boundary?.properties ?? {}),
              name: paddock.name,
              area: paddock.area,
              areaHectare: paddock.areaHectare,
              status: paddock.status,
              fieldId: field.id,
              fieldName: field.name,
            },
          };
        }),
    ] as Feature[];
  }

  function addField(field?: Field | Field[]) {
    if (enableAsyncFieldProcessing && country === 'us') {
      setProcessingStatus(EnumProcessingStatus.NOT_PROCESSED);
      setIneligibleAreasFinalized(false);
    }

    if (field) {
      let allFields: Array<Field>;
      if (isArray(field)) {
        allFields = [...fields, ...field];
      } else {
        allFields = [...fields, field];
      }
      setFields([]);
      getProcessedFieldsBoundaries([...allFields])
        .then((processedFields) => {
          setFields(() => {
            verifyNearFieldsAndOverlap([...allFields]);
            return updateFieldsIntersections(processedFields);
          });
        })
        .then(() => {
          setUploadingBoundaries(false);
        });
      updatedFieldBoundary.current = true;
      setWillLoseProgressOnExit(true);
      return;
    }
    setMode(MAP_MODES.SIMPLE_SELECT);
    // @ts-ignore
    draw?.draw.combineFeatures();
    // @ts-ignore
    const newField = draw?.draw.getAll().features[0];

    if (!newField) {
      return;
    }
    updatedFieldBoundary.current = true;
    setWillLoseProgressOnExit(true);
    newField.properties.area = calculateFieldArea(newField);
    newField.properties.areaHectare = calculateFieldArea(newField, true);
    newField.properties.country = i18next.language === 'pt-BR' ? 'Brazil' : 'United States';
    newField.properties.area_unit = i18next.language === 'pt-BR' ? 'hectares' : 'acres';
    newField.properties.name = `${t('fieldFlow.field')} #${lastFieldNumSaved + fields.length}`;
    newField.properties.situation = 'OWNED';
    newField.properties.practiceType = i18next.language === 'pt-BR' ? 'PASTURE' : 'ROW_CROP';
    newField.properties.paymentOption = undefined;
    newField.properties.irrigated = false;
    newField.properties.legume = false;
    newField.properties.previousTillage = 'intensive';
    newField.properties.practices = [];
    // The 'parent' property is used to reference the field for editing
    newField.properties.parent = newField.id;
    getStateAndCounty(newField).then((res) => (newField.properties = { ...newField.properties, ...res }));
    newField.properties.editing = false;

    setMode(MAP_MODES.STATIC);

    // @ts-ignore
    draw?.draw.deleteAll();
    getProcessedFieldsBoundaries([newField])
      .then(([processedFields]) => {
        setFields((fields) => {
          verifyNearFieldsAndOverlap([...fields, processedFields]);
          return updateFieldsIntersections([...fields, processedFields]);
        });
      })
      .then(() => {
        setUploadingDrawnField(false);
      });
  }

  async function verifyNearFieldsAndOverlap(body, overwriteValue?: boolean) {
    if (!journeyId) return;
    const data = body.map((i) => {
      if (!i.boundaries) {
        return { boundaries: i, unenrolledAt: i.properties.unenrolledAt };
      }
      return i;
    });
    setOverlaps(null);

    const shouldShowCloserFields = overwriteValue ?? showCloserFields;
    if (shouldShowCloserFields && !isChannelPartner) {
      await API.post(`${FIELD_URL}/field-service/fields/near-fields?journeyId=${journeyId}`, data)
        .then((nearFieldsResponse) => {
          if (nearFieldsResponse.data?.length) {
            setNearFields(nearFieldsResponse.data);
          } else {
            setNearFields([]);
          }
        })
        .catch((err) => {
          console.log('Near Fields error: ', err);
          showToast(t('fieldFlow.errors.error'), `${t('fieldFlow.errors.nearFields')}`, 'error', 'near-fields-error');
        });
    } else {
      setNearFields([]);
    }
    const overlaps = await verifyOverlaps(data);
    setOverlaps(overlaps);
  }

  async function toggleShowNearFields(toggleValue: boolean) {
    setShowCloserFields(toggleValue);
    await verifyNearFieldsAndOverlap(fields, toggleValue);
    setFields((fields) => {
      return updateFieldsIntersections(fields);
    });
  }

  function startSplittingField(field: Field | string) {
    _editField(field, 'SPLIT');
  }

  function startEditingField(field: Field | string) {
    _editField(field, 'EDIT');
  }

  function _editField(field: Field | string, editMode: 'SPLIT' | 'EDIT') {
    // @ts-ignore
    const features = draw?.draw.getAll()?.features;

    if ((features && features.length) || mode === MAP_MODES.AUTO_DIRECT_SELECT) {
      return;
    }

    const fieldId = typeof field === 'string' ? field : field.id;
    const treatedFields = fields.map((fieldSet) => {
      fieldSet.properties = fieldSet.properties ?? {};
      fieldSet.properties['editing'] = fieldSet.id === fieldId;
      if (fieldSet.id === fieldId) {
        if (editMode === 'SPLIT') {
          if (paddocks[fieldId!] && paddocks[fieldId!].length) {
            // @ts-ignore
            paddocks[fieldId!].map((paddock) => draw?.draw.add(paddock));
          } else {
            // @ts-ignore
            draw?.draw.add(fieldSet);
          }

          setMode(MAP_MODES.SPLIT_FIELD);
        } else if (editMode === 'EDIT') {
          if (enableAsyncFieldProcessing) {
            // @ts-ignore
            draw?.draw.add(fieldSet.properties.processedProperties?.original?.boundaries ?? fieldSet);
          } else {
            // @ts-ignore
            draw?.draw.add(fieldSet);
          }

          setMode(MAP_MODES.AUTO_DIRECT_SELECT);
        }
      }

      return fieldSet;
    });

    return setFields(updateFieldsIntersections([...treatedFields]));
  }

  function updateFieldInfo(field: Field) {
    setWillLoseProgressOnExit(true);
    setProcessingStatus(EnumProcessingStatus.NOT_PROCESSED);
    setIneligibleAreasFinalized(false);
    setFields((fields) => {
      const index = fields.findIndex((fieldSearch) => fieldSearch.id === field.id);
      if (fields[index].properties?.paymentOption !== field.properties?.paymentOption && i18next.language === 'pt-BR') {
        fields = fields.map((fieldOld) => {
          fieldOld.properties = fieldOld.properties ?? {};
          fieldOld.properties.paymentOption = field.properties?.paymentOption;
          return fieldOld;
        });
      } else {
        return [...fields.slice(0, index), { ...field }, ...fields.slice(index + 1)];
      }
      return [...fields];
    });
  }

  function updateFieldBoundary() {
    const editedField = fields.find((field) => field.properties?.['editing']);

    if (!editedField) {
      return;
    }

    setMode(MAP_MODES.SIMPLE_SELECT);
    // @ts-ignore
    draw?.draw.combineFeatures();
    // @ts-ignore
    const newField = draw?.draw.getAll().features[0];

    if (!newField) {
      setUploadingDrawnField(false);
      return;
    }

    // @ts-ignore
    const sameBoundary =
      newField.geometry.coordinates.toString() ===
      (editedField.properties?.processedProperties?.original?.boundaries || editedField).geometry.coordinates!.toString();
    if (sameBoundary) {
      // @ts-ignore
      editedField.properties.editing = false;
      newField.properties.editing = false;
      // @ts-ignore
      draw?.draw.deleteAll();
      setMode(MAP_MODES.STATIC);
      // reset drawing state
      setFields((fields) => {
        return [...fields];
      });
      setUploadingDrawnField(false);
      return;
    }

    updatedFieldBoundary.current = true;
    setWillLoseProgressOnExit(true);

    newField.id = editedField.id;
    newField.properties = editedField.properties;
    newField.properties.area = calculateFieldArea(newField);
    newField.properties.areaHectare = calculateFieldArea(newField, true);

    if (!!newField.properties.processedProperties?.original) {
      newField.properties.processedProperties.original = {
        area: newField.properties.area,
        areaHectare: newField.properties.areaHectare,
        ineligibleAreaAcres: 0,
        ineligibleAreaHectares: 0,
        boundaries: {
          geometry: newField.geometry,
          type: 'Feature',
        },
      };
    }

    newField.properties.ineligibleAreasFinalized = false;
    setProcessingStatus(EnumProcessingStatus.NOT_PROCESSED);
    setIneligibleAreasFinalized(false);

    getStateAndCounty(newField).then((res) => (newField.properties = { ...newField.properties, ...res }));
    newField.properties.editing = false;

    setMode(MAP_MODES.STATIC);

    // @ts-ignore
    draw?.draw.deleteAll();

    // so the drawing is consistent
    setFields((fields) => {
      fields[fields.indexOf(editedField)] = newField;
      return [...fields];
    });
    getProcessedFieldsBoundaries([newField])
      .then((processedFields) => {
        setFields((fields) => {
          const i = fields.findIndex((f) => f.id === editedField.id);
          fields[i] = processedFields[0];
          verifyNearFieldsAndOverlap([...fields]);
          return updateFieldsIntersections([...fields]);
        });
      })
      .then(() => {
        setUploadingDrawnField(false);
      });
  }

  function updateField(field?: Field) {
    if (!field) {
      updateFieldBoundary();
    } else {
      updateFieldInfo(field);
    }
  }

  function calculateFieldArea(field: any, hectare = false) {
    const areaConversion = hectare ? kmToHectare : kmToAcre;

    if (field.geometry?.type === 'MultiPolygon') {
      return field.geometry.coordinates.reduce((i: number, coordinates: number[][][]) => {
        return i + polygonArea(coordinates) * areaConversion;
      }, 0);
    } else {
      return polygonArea(field.geometry.coordinates) * areaConversion;
    }
  }

  function getStateAndCounty(field: Field): Promise<{ state: string; county: string }> {
    return AddressSearchService.searchAddress(getFieldCenter(field).join(','), ['district', 'region']).then((res) => {
      return {
        state: res?.features?.find((f) => f.place_type[0] === 'region')?.properties.short_code.split('-')[1],
        county: res?.features?.find((f) => f.place_type[0] === 'district')?.text.replace(' County', ''),
      };
    });
  }

  function removeField(field: Field) {
    updatedFieldBoundary.current = true;
    setWillLoseProgressOnExit(true);
    setRemovedFields((removedFields) => [...removedFields, field]);
    setFields((fields) => {
      const newArray = [...fields].filter((f) => f.id !== field.id);
      if (enableAsyncFieldProcessing && country === 'us' && newArray.length === 0) {
        setProcessingStatus(EnumProcessingStatus.NOT_PROCESSED);
        setIneligibleAreasFinalized(false);
      }
      verifyNearFieldsAndOverlap(newArray);
      return updateFieldsIntersections(newArray);
    });
  }

  function removeAllFields() {
    updatedFieldBoundary.current = true;
    setWillLoseProgressOnExit(true);
    setRemovedFields((removedFields) => [...removedFields, ...fields]);
    setFields([]);
    setNearFields([]);
    setOverlaps([]);
    if (enableAsyncFieldProcessing && country === 'us') {
      setProcessingStatus(EnumProcessingStatus.NOT_PROCESSED);
      setIneligibleAreasFinalized(false);
    }
  }

  function removeFieldsAPICall() {
    Promise.all(removedFields.map((f) => API.delete(`${FIELD_URL}/field-service/fields/${f.id}`))).catch(() => null);
    setRemovedFields([]);
    if (enableAsyncFieldProcessing && country === 'us') {
      setProcessingStatus(EnumProcessingStatus.NOT_PROCESSED);
    }
  }

  function saveFields(): Promise<any> {
    removeFieldsAPICall();
    if (!willLoseProgressOnExit) return Promise.resolve();

    const body = fields.map((field) => {
      delete field.properties!['selected'];
      delete field.properties!.lineColor;
      delete field.properties!.lineWidth;
      delete field.properties!.fillColor;
      delete field.properties!.fillOpacity;
      return {
        id: field.id,
        growerId: journey?.leadId ?? journey?.opportunityId,
        opportunityId: journey?.opportunityId,
        journeyId: journey?.id,
        name: field.properties!['name'],
        area: field.properties!['area'],
        areaHectare: field.properties!['areaHectare'],
        status: 'VISIBLE',
        situation: field.properties!['situation'],
        practiceType: field.properties!['practiceType'],
        practices: field.properties!['practices'],
        historicalPractices: field.properties!['historicalPractices'],
        paymentOption: field.properties!['paymentOption'],
        boundaries: {
          ...field,
          properties: {
            processedProperties: field.properties!.processedProperties,
            ineligibleAreaAcres: field.properties!.ineligibleAreaAcres,
            ineligibleAreaHectares: field.properties!.ineligibleAreaHectares,
            ineligibleAreasFinalized: field.properties!.ineligibleAreasFinalized,
            area: field.properties!.area,
            areaHectare: field.properties!.areaHectare,
          },
        },
        country: field.properties!['country'],
        county: field.properties!['county'],
        state: field.properties!['state'],
        irrigated: field.properties!['irrigated'],
        legume: field.properties!['legume'],
        previousTillage: field.properties!['previousTillage'],
      };
    });

    // return API.post2(store, 'http://localhost:3019/field-service/fields', body)
    return API.post(`${FIELD_URL}/field-service/fields`, body)
      .then(() => {
        setWillLoseProgressOnExit(false);
        updatedFieldBoundary.current = false;
      })
      .catch((err) => {
        console.log(`Couldn't save fields: saveFields error - ${JSON.stringify(err)}`);
        throw err;
      });
  }

  function focusField(field?: Field) {
    if (field?.properties?.selected) return;
    setFields((fields) => {
      return [
        ...fields.map((fieldSet) => {
          fieldSet.properties = fieldSet.properties ?? {};
          fieldSet.properties['selected'] = fieldSet.id === field?.id;
          return fieldSet;
        }),
      ];
    });
  }

  function updatePaddock(feature: Feature) {
    const fieldId = feature.properties!['fieldId'];

    setPaddocks((paddocks) => {
      const index = paddocks[fieldId].findIndex((paddock) => paddock.id === feature.id);
      paddocks[fieldId][index] = feature;
      return {
        ...paddocks,
        [fieldId]: [...paddocks[fieldId]],
      };
    });

    setPaddocksInvalid(mode !== MAP_MODES.STATIC || arePaddocksNamesInvalid());
  }

  function restartPaddock(field: Feature) {
    setFinishedDrawingPaddock(true);
    paddocks[field.id!.toString()] = [];
    setPaddocks({ ...paddocks });

    startSplittingField(field);

    setMode(MAP_MODES.SPLIT_FIELD);
  }

  const arePaddocksNamesInvalid = useCallback(() => {
    return fields.reduce((invalidFields: boolean, field) => {
      if (!field.id) return false;
      return (
        invalidFields ||
        paddocks?.[field!.id]?.reduce((invalidPaddocks: boolean, paddock) => {
          return invalidPaddocks || !paddock.properties!['name'];
        }, false)
      );
    }, false);
  }, [fields, paddocks]);

  //Triggers when selecting one of the drawing options
  useEffect(() => {
    if (!draw) return;
    // @ts-ignore
    const features = draw?.draw.getAll().features ?? [];

    if (
      features.length === 0 ||
      features.some((feat: any) => feat.geometry.coordinates.some((coordinates: any) => !coordinates.length || coordinates[0] === null))
    ) {
      return setFinishedDrawingField(false);
    }
    setFinishedDrawingField(true);
  }, [draw?.draw, mode]);

  useEffect(() => {
    setPaddocksInvalid(mode !== MAP_MODES.STATIC || arePaddocksNamesInvalid());
  }, [arePaddocksNamesInvalid, mode]);

  //Triggers when user finishes using a mode
  function onModeFinish(event: any) {
    setMode(event.mode);
    setFinishedDrawingField(true);
    if (event.mode === 'split_field') setFinishedDrawingPaddock(true);
  }

  function onDrawUpdate(event: any) {
    if (event.action === 'CreatePaddockVertex') {
      return setFinishedDrawingPaddock(false);
    }

    if (!event.features.length || event.features.length === 1) {
      return;
    }

    const features = event.features.map((feature: any) => {
      return {
        id: feature.id,
        type: 'Feature',
        geometry: {
          type: feature.type,
          coordinates: feature.coordinates,
        },
        properties: {
          ...feature.properties,
          fieldId: feature.properties.fieldId,
          fieldName: feature.properties.fieldName || feature.properties.name,
        },
      } as Feature;
    });

    paddocksBeingDrawn.current = features.map((newPaddock: Feature) => {
      newPaddock.properties = newPaddock.properties || {};
      newPaddock.id = uuidv4();
      newPaddock.properties['area'] = turf.area(newPaddock) * kmToAcre;
      newPaddock.properties['areaHectare'] = turf.area(newPaddock) * kmToHectare;
      newPaddock.properties['name'] = `${t('fieldFlow.herdItem.locationInfo.paddock')} #${features.indexOf(newPaddock) + 1}`;
      newPaddock.properties['dominantPlant'] = '';
      return newPaddock;
    });
  }

  function setDrawnPaddocksForField(field: Field) {
    if (!paddocksBeingDrawn.current) {
      setUploadingDrawnField(false);
      return;
    }

    const drawnPaddocks = [...paddocksBeingDrawn.current!];
    paddocksBeingDrawn.current = undefined;
    setPaddocks((paddocks) => {
      paddocks[field.id!.toString()] = drawnPaddocks;
      return paddocks;
    });
    setUploadingDrawnField(false);
  }

  const isFieldsDrawingInvalid = useCallback(() => {
    return !(
      (mode === MAP_MODES.STATIC || mode === MAP_MODES.AUTO_DIRECT_SELECT) &&
      //@ts-ignore
      (finishedDrawingField || (draw?.draw.getAll().features.length ?? 0) === 0)
    );
  }, [draw?.draw, finishedDrawingField, mode]);

  const areFieldsIntersecting = useCallback(() => {
    return fields.some((f) => f.properties?.intersects);
  }, [fields]);

  useEffect(() => {
    setFieldsIntersecting(areFieldsIntersecting);
    setFieldsDrawingInvalid(isFieldsDrawingInvalid);
  }, [areFieldsIntersecting, fields, isFieldsDrawingInvalid, mode]);

  function getFieldCenter(field: Field) {
    return turf.center(turf.featureCollection([field])).geometry.coordinates;
  }

  function getFieldsLocations(): Array<string> {
    return Array.from(
      new Set(
        fields.filter((f) => !!f.properties?.county && !!f.properties?.state).map((f) => f.properties?.county + ', ' + f.properties?.state),
      ).values(),
    );
  }

  function getFieldsPractices(): Array<string> {
    return Array.from(
      new Set(
        fields
          .filter((f) => !!f.properties?.practices.length)
          .map((f) => f.properties?.practices)
          .flat(),
      ),
    );
  }

  function upsertShapefile(shapefileStatus: ShapefileStatus, estimatedCarbon?: number) {
    return API.put(`${FIELD_URL}/field-service/shapefiles/status`, {
      journeyId: journey?.id,
      shapefileStatus,
      country,
      estimatedCarbon,
      contractPaymentOption,
    }).catch((err) => {
      console.log(`Couldn't upsert shapefile: upsertShapefile error - ${JSON.stringify(err)}`);
      throw err;
    });
  }

  function treatPractices(f, practice, appliedPractices, areaKey, paymentOptionBR) {
    const practiceCode =
      practice === 'ICL' ? SF_PRACTICES_MAP[practice + (f.properties.practiceType === 'PASTURE' ? '1' : '2')] : SF_PRACTICES_MAP[practice];
    const paymentOption = ['NO', 'NRR'].indexOf(practiceCode) >= 0 ? 'ANNUAL' : f.properties.paymentOption || paymentOptionBR;
    let currentPlatformPractice = appliedPractices.find((i) => practiceCode === i.name && paymentOption === i.option);
    if (!currentPlatformPractice) {
      currentPlatformPractice = { name: practiceCode, sf: 0, platform: f.properties[areaKey], option: paymentOption };
      appliedPractices.push(currentPlatformPractice);
    } else {
      currentPlatformPractice.platform += f.properties[areaKey];
    }
  }

  function treatPromotionPractices(f, appliedPractices, areaKey: string) {
    if (f.properties.practices?.length >= 3) {
      const promotionPractice = appliedPractices.find((i) => i.name === 'TPA');
      if (!promotionPractice) {
        appliedPractices.push({ name: 'TPA', sf: 0, platform: f.properties[areaKey], option: null });
      } else {
        promotionPractice.platform += f.properties[areaKey];
      }
    } else if (f.properties.practices?.length >= 2) {
      const promotionPractice = appliedPractices.find((i) => i.name === 'DPA');
      if (!promotionPractice) {
        appliedPractices.push({ name: 'DPA', sf: 0, platform: f.properties[areaKey], option: null });
      } else {
        promotionPractice.platform += f.properties[areaKey];
      }
    } else {
      const promotionPractice = appliedPractices.find((i) => i.name === 'SPA');
      if (!promotionPractice) {
        appliedPractices.push({ name: 'SPA', sf: 0, platform: f.properties[areaKey], option: null });
      } else {
        promotionPractice.platform += f.properties[areaKey];
      }
    }
  }

  function analysePaymentOptions(appliedPractices) {
    const contractPaymentOptionMap = {
      OPTION_A: 'Preço fixo',
      OPTION_B: 'Porcentagem do preço de venda',
      'Preço fixo': 'Preço fixo',
      'Porcentagem do preço de venda': 'Porcentagem do preço de venda',
    };
    const paymentOptionBR = contractPaymentOption ? contractPaymentOptionMap[contractPaymentOption] : undefined;
    const areaKey = country === 'br' ? 'areaHectare' : 'area';
    const paymentOptionErrors: any = [];
    const missingPractices: string[] = [];
    const hasPromotionPractices = appliedPractices.find((i) => ['SPA', 'DPA', 'TPA'].indexOf(i.name) >= 0);

    for (const field of fields.filter((i) => !i.properties?.unenrolledAt)) {
      const f: any = field;
      if (!f.properties?.practices.length && country === 'br') {
        missingPractices.push(f.id);
      }
      if (!f.properties.paymentOption && country !== 'br') {
        paymentOptionErrors.push({ id: f.id, name: f.properties.name, area: f.properties.area });
      }
      for (const practice of f.properties.practices) {
        treatPractices(f, practice, appliedPractices, areaKey, paymentOptionBR);
      }
      if (hasPromotionPractices) {
        treatPromotionPractices(f, appliedPractices, areaKey);
      }
    }

    return { paymentOptionErrors, missingPractices };
  }

  function comparePractices(appliedPractices) {
    const newPractices: any[] = [];
    const removedPractices: any[] = [];
    const upsellingFlow = country === 'us' && upsellingBoundaryTool && !!producerData?.caseStatusHistory?.find((i) => i.status === 'In Support');
    for (const practice of appliedPractices) {
      if (practice.sf === 0 && practice.platform > 0 && practice.option !== 'UPSELL') {
        newPractices.push(practice);
      }
      if (practice.sf > 0 && practice.platform === 0 && practice.option !== 'UPSELL') {
        removedPractices.push(practice);
      }
    }

    let practiceErrors: any = [];
    if (!upsellingFlow) {
      if (newPractices.length) {
        practiceErrors = practiceErrors.concat(newPractices);
      }
    }
    if (removedPractices.length) {
      practiceErrors = practiceErrors.concat(removedPractices);
    }

    return { practiceErrors, newPractices };
  }

  async function getSyncAvailability() {
    const practicesOnContract = await getPracticesOnContract();
    const practicesToValidate = Object.values(SF_PRACTICES_MAP);
    const appliedPractices: any[] = practicesOnContract
      .filter((i) => i.isApplied)
      .filter((i) => practicesToValidate.indexOf(i.code) >= 0)
      .map((i) => {
        return { name: i.code, sf: i.area, platform: 0, option: i.paymentOption };
      });

    const { paymentOptionErrors, missingPractices } = analysePaymentOptions(appliedPractices);
    const { practiceErrors, newPractices } = comparePractices(appliedPractices);

    const response: any = {
      practices: practiceErrors,
      paymentOptionErrors: paymentOptionErrors,
      missingPractices, //Only used in BR
      newPractices,
      status: practiceErrors.length || paymentOptionErrors.length ? 'ERROR' : 'OK',
    };
    setSyncErrors(response);
    return response;
  }

  function verifyOverlaps(body) {
    if (!journeyId) return [];
    return API.post(`${FIELD_URL}/field-service/fields/intersections?journeyId=${journeyId}`, body).then((response: any) => {
      if (response.data?.length) {
        return response.data;
      }
      return [];
    });
  }

  function getProcessedFieldsBoundaries(boundaries: Array<Feature>) {
    if (!removeIneligibleAreasAutomatically)
      return Promise.resolve(
        boundaries.map((b) => {
          b.properties = {
            ...b.properties,
            originalBoundary:
              enableAsyncFieldProcessing && country === 'us'
                ? null
                : {
                    ...b,
                    properties: undefined,
                  },
          };
          return b;
        }),
      );
    return API.post(`${FIELD_URL}/field-service/fields/process-fields-boundaries`, boundaries)
      .then((response: any) => {
        if (response.data?.length) {
          return response.data.map((b) => {
            const originalBoundary = boundaries.find((ob) => b.id === ob.id);
            b.properties.originalBoundary = {
              ...originalBoundary,
              properties: undefined,
            };
            return b;
          });
        }
        return [];
      })
      .catch((err) => {
        console.log('Process boundaries error: ', err);
        showToast(t('fieldFlow.errors.error'), `${t('fieldFlow.errors.processField')}`, 'error', 'process-boundaries-error');
      });
  }

  function updateFieldsIntersections(fields: Array<Feature>) {
    const intersectFields = [...fields].map((f) => {
      return { ...f, properties: { ...f.properties, intersects: false } };
    });
    for (let i = 0; i < intersectFields.length; i++) {
      intersectFields[i].properties.intersects = MapUtilities.findMultiPolygonIntersection(intersectFields[i]);
      for (let j = i; j < intersectFields.length; j++) {
        if (intersectFields[i].id === intersectFields[j].id) continue;
        if (
          // @ts-ignore
          !intersectFields[i].properties.unenrolledAt &&
          // @ts-ignore
          !intersectFields[j].properties.unenrolledAt &&
          MapUtilities.findFeaturesIntersection(intersectFields[i], intersectFields[j])
        ) {
          intersectFields[i].properties.intersects = true;
          intersectFields[j].properties.intersects = true;
        }
      }
    }
    return intersectFields;
  }

  function getPracticeData(fields: Field[]) {
    const hasPromotionPractices = practicesOnContract.find((i) => ['SPA', 'DPA', 'TPA'].indexOf(i.code) >= 0);
    const practiceData: any = {};
    fields.forEach((f) => {
      if (f.properties?.practices?.length) {
        f.properties.practices.forEach((practice: string) => {
          let index: string;
          const option = practice === 'nitrogenReduction' || practice === 'nitrogenOptimization' ? 'ANNUAL' : f.properties?.paymentOption;
          //Contract paymentOptions is used to dictate brazilian field paymentOptions
          if (f.properties?.country === 'Brazil') {
            index = practice;
          } else {
            index = practice + option;
          }
          if (!practiceData[index]) {
            practiceData[index] = {
              name: practice,
              option: option,
              platform: Number(f.properties?.[i18next.language === 'pt-BR' ? 'areaHectare' : 'area']),
              sf: 0,
            };
          } else {
            practiceData[index].platform += Number(f.properties?.[i18next.language === 'pt-BR' ? 'areaHectare' : 'area']);
          }
        });
      }
      if (hasPromotionPractices) {
        if (f?.properties?.practices?.length >= 3) {
          if (!practiceData['triplePracticeIncentive' + f.properties?.paymentOption]) {
            practiceData['triplePracticeIncentive' + f.properties?.paymentOption] = {
              name: 'triplePracticeIncentive',
              option: f.properties?.paymentOption,
              platform: Number(f.properties?.[i18next.language === 'pt-BR' ? 'areaHectare' : 'area']),
              sf: practicesOnContract.find((i) => i.code === 'triplePracticeIncentive')?.area || 0,
            };
          } else {
            practiceData['triplePracticeIncentive' + f.properties?.paymentOption].platform += Number(
              f.properties?.[i18next.language === 'pt-BR' ? 'areaHectare' : 'area'],
            );
          }
        } else if (f?.properties?.practices?.length >= 2) {
          if (!practiceData['dualPracticeIncentive' + f.properties?.paymentOption]) {
            practiceData['dualPracticeIncentive' + f.properties?.paymentOption] = {
              name: 'dualPracticeIncentive',
              option: f.properties?.paymentOption,
              platform: Number(f.properties?.[i18next.language === 'pt-BR' ? 'areaHectare' : 'area']),
              sf: practicesOnContract.find((i) => i.code === 'dualPracticeIncentive')?.area || 0,
            };
          } else {
            practiceData['dualPracticeIncentive' + f.properties?.paymentOption].platform += Number(
              f.properties?.[i18next.language === 'pt-BR' ? 'areaHectare' : 'area'],
            );
          }
        } else {
          if (!practiceData['singlePracticeIncentive' + f.properties?.paymentOption]) {
            practiceData['singlePracticeIncentive' + f.properties?.paymentOption] = {
              name: 'singlePracticeIncentive',
              option: f.properties?.paymentOption,
              platform: Number(f.properties?.[i18next.language === 'pt-BR' ? 'areaHectare' : 'area']),
              sf: practicesOnContract.find((i) => i.code === 'singlePracticeIncentive')?.area || 0,
            };
          } else {
            practiceData['singlePracticeIncentive' + f.properties?.paymentOption].platform += Number(
              f.properties?.[i18next.language === 'pt-BR' ? 'areaHectare' : 'area'],
            );
          }
        }
      }
    });

    for (const key in practiceData) {
      practiceData[key].sf = Number(practiceData[key].sf.toFixed(2));
      practiceData[key].platform = Number(practiceData[key].platform.toFixed(2));
    }

    const res = Object.keys(practiceData).map((k) => practiceData[k]);
    res.sort((a, b) => (a.name > b.name ? 1 : -1));
    return res;
  }

  const gettingPracticesOnContract = useRef<boolean>(false);

  useEffect(() => {
    getPracticesOnContract();
  }, [journeyId, producerData]);

  function getPracticesOnContract() {
    if (!journeyId || !producerData || gettingPracticesOnContract.current) return;

    gettingPracticesOnContract.current = true;
    const isBrUser = country === 'br';
    const pricebookId = producerData?.Pricebook2Id;
    const opportunityId = producerData?.opportunityId;
    return API.post(`${CONTRACT_URL}/contract-service/contract/contract-versions/active-version?country=${isBrUser ? 'BR' : 'US'}`, {
      optionAOnly: false,
    })
      .then((res) => {
        const templateId = res.data.docusign_id;
        return API.get(`${BASE_URL}/base/producer/contract-practices`, {
          opportunityId,
          pricebookId,
          templateId,
        }).then((response: any) => {
          setPracticesOnContract(response?.data);
          return response?.data;
        });
      })
      .catch((err) => {
        // TODO add a proper error handling
        console.log(`Couldn't get practices: getPracticesOnContract error - ${JSON.stringify(err)}`);
        throw err;
      })
      .finally(() => {
        gettingPracticesOnContract.current = false;
      });
  }

  function notifyUpdate(producerName: string, urlProducerProfile: string, updatedBy: User) {
    let channel;
    let text;
    if (brBoundaryApproval) {
      channel = 'NELI_DM';
      text = `*Vendas*: O arquivo de limites do produtor *_${producerName}_* (que já tinha passado por análise de hectares) *foi atualizado (os limites mudaram).* Clique aqui para ver o novo arquivo e verifique se uma nova análise é necessária: ${urlProducerProfile}.\nLembre-se de aprovar os limites novamente.`;
    } else {
      let username: string | undefined;
      if (updatedBy?.firstName) {
        username = `${updatedBy.firstName} ${updatedBy.lastName}`;
      } else {
        username = updatedBy.fullName ?? updatedBy.email;
      }
      channel = 'BR_SALES_GC';
      text = `*Vendas*: O arquivo de limites do produtor _${producerName}_ foi atualizado na Plataforma${
        username ? ` por *${username}*` : ''
      }. Clique aqui para ver o novo arquivo: ${urlProducerProfile}.\nLembre-se de modificar a estimativa para ter as mesmas informações dos limites (práticas e hectares).`;
    }
    // TODO: CHANGE THIS TO CALL SLACK CORRECTLY
    return API.post(`${BASE_URL}/slack-message`, {
      username: 'Agoro Bot',
      channel,
      text,
    }).catch(() => {
      showToast(t('fieldFlow.errors.error'), t('fieldFlow.errors.notification'), 'error', 'sales-boundary-notification-error');
    });
  }

  function changeFieldEnrollmentStatus(field: Field, status: boolean, hidden: boolean = false) {
    setLoadingFields(true);
    const URL = `${FIELD_URL}/field-service/fields/enrolled-status/${field.id}`;
    const body = { status: !status ? EnrollmentStatus.ENROLLED : EnrollmentStatus.UNENROLLED };
    API.post(URL, body)
      .then(() => changeEnrollment(field, status, hidden))
      .finally(() => setLoadingFields(false));
  }

  async function changeEnrollment(field: Field, status: boolean, hidden: boolean) {
    const f = fields.find((f) => field.id === f.id);
    f!.properties!.unenrolledAt = status ? new Date() : null;
    f!.properties!.hidden = hidden;
    await verifyNearFieldsAndOverlap(fields);
    setFields((fields) => {
      return updateFieldsIntersections(fields);
    });
  }

  function toggleHideFields(hidden: boolean) {
    setFields((fields) => {
      return fields.map((f) => {
        if (f.properties?.unenrolledAt) {
          f.properties.hidden = hidden;
        }
        return f;
      });
    });
  }

  function getFieldProcessingStatus(onProcessed?: () => void) {
    if (!enableAsyncFieldProcessing || country !== 'us') {
      setProcessingStatus(EnumProcessingStatus.PROCESSED);
      return;
    }
    const j = journey?.id ?? journeyId;
    if (j) {
      const interval: any = setInterval(async () => {
        try {
          const { data } = await API.get(`${FIELD_URL}/field-service/fields/field-processing/status/${j}`);
          setProcessingStatus(data.status);
          if (data.status === EnumProcessingStatus.PROCESSED) {
            clearInterval(interval);
            if (!!onProcessed) onProcessed();
            await getFields();
          }
        } catch (e) {
          console.log(`getFieldProcessingStatus error - `, JSON.stringify(e));
        }
      }, 5000);
      return interval;
    }
  }

  async function startFieldProcessing() {
    if (!enableAsyncFieldProcessing || country !== 'us') {
      return;
    }
    const j = journey?.id ?? journeyId;
    if (j) {
      try {
        await API.post(`${FIELD_URL}/field-service/fields/field-processing/${j}`, {});
        setProcessingStatus(EnumProcessingStatus.PROCESSING);
      } catch (e) {
        console.log(`startFieldProcessing error - `, JSON.stringify(e));
      }
    }
  }

  async function finalizeFieldProcessing(choices: Array<string>) {
    if (!enableAsyncFieldProcessing || country !== 'us') {
      return;
    }
    const j = journey?.id ?? journeyId;
    if (j) {
      try {
        const response = await API.post(`${FIELD_URL}/field-service/fields/field-processing/${j}/finalize-ineligible-area`, { choices });
        const status = response.data?.status;
        setIneligibleAreasFinalized(status === 'FINALIZED');
        await getFields();
      } catch (e) {
        console.log(`finalizeFieldProcessing error - `, JSON.stringify(e));
      }
    }
  }

  return {
    loadingFields,
    setLoadingFields,
    finishedDrawingField,
    setFinishedDrawingField,
    finishedDrawingPaddock,
    updatedFieldBoundary,
    willLoseProgressOnExit,
    setWillLoseProgressOnExit,

    uploadingBoundaries,
    setUploadingBoundaries,
    uploadingDrawnField,
    setUploadingDrawnField,

    fields,
    fieldsIntersecting,
    fieldsDrawingInvalid,
    paddocksInvalid,
    totalFieldsAcreage,
    getFields,
    addField,
    updateField,
    removeField,
    removeAllFields,
    focusField,
    startSplittingField,
    startEditingField,
    deleteField,
    setDeleteField,
    deleteAllFields,
    setDeleteAllFields,
    saveFields,
    getFieldCenter,
    getFieldsLocations,
    getFieldsPractices,
    notifyUpdate,

    upsertShapefile,
    getSyncAvailability,
    syncErrors,
    setSyncErrors,

    paddocks,
    setPaddocks,
    setDrawnPaddocksForField,
    updatePaddock,
    restartPaddock,
    onDrawUpdate,
    getPracticeData,
    onModeChange: onModeFinish,

    practicesOnContract,
    nearFields,
    overlaps,

    changeFieldEnrollmentStatus,
    toggleHideFields,
    toggleShowNearFields,

    processingStatus,
    startFieldProcessing,
    getFieldProcessingStatus,
    finalizeFieldProcessing,
    ineligibleAreasFinalized,
  };
};

import { AnyAction } from '@reduxjs/toolkit';

const initState: any = {
  accessToken: null,
  isInitialized: false,
  getAccessTokenFn: null,
  refreshSessionFn: null,
};

const reducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case 'ACCESS_TOKEN_LOADED': {
      return {
        ...state,
        accessToken: action.payload.result,
        refreshSessionFn: action.payload.refreshSessionFn,
      };
    }
    case 'APP_INITIALIZED': {
      return {
        ...state,
        isInitialized: true,
      };
    }
    case 'AVAILABLE_STATES_SUCCESSFUL': {
      if (action.payload.country === 'BR') {
        return {
          ...state,
          brazilStates: action.payload.list,
        };
      } else {
        return {
          ...state,
          stateList: action.payload.list,
        };
      }
    }
  }
  return state;
};
export default reducer;

import { useCallback, useEffect, useRef, useState } from 'react';
import { MAP_MODES } from '../../components/Drawing/modes/modes';
import * as turf from '@turf/turf';
import { useSelector } from 'react-redux';
import DrawControl from '../../components/Map/DrawControl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { getPaddedBBox } from '../common/utils';
import { FieldLayout } from '../../types/FieldLayout';
import { useStructureService } from '../StructureService';
import { useHerdService } from '../HerdService';
import { useEventService } from '../EventService';
import { useFieldService } from '../FieldService';
import { Feature } from '@turf/helpers';
import { v4 } from 'uuid';
import { states } from './MapContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Journey } from '../../types/Journey';
import { StateUrl } from './MarkerHelper';
import { ShapefileStatus } from '../../types/Shapefiles';
import { API } from '../../../../service/common/api';
import { BASE_URL, CONTRACT_URL, ESTIMATE_URL, FIELD_URL } from '../../../../service/common/env';
export const fitBoundsOption = { duration: 0, padding: { top: 0, left: 0, bottom: 0, right: 500 } };

export const useMapService = () => {
  const { shouldLdAllowBoundaryEdit, brFinalizeBoundaries, cpBoundaryTool, hideStructuresAndEvents, hideGrazing, enableAsyncFieldProcessing } =
    useFlags();
  const { journeyId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [map, setMap] = useState<mapboxgl.Map | undefined>();
  const [draw, setDraw] = useState<DrawControl | null>(null);
  const [mode, setCurrentMode] = useState<string>(MAP_MODES.STATIC);
  const [state, setState] = useState<states>('OVERVIEW');
  const [country, setCountry] = useState<string>('us');

  const [currentVersion, setCurrentVersion] = useState<FieldLayout>();
  const [companyName, setCompanyName] = useState<string>('--');

  const fieldId = searchParams.get('fieldId');
  const [shapefileStatus, setShapefileStatus] = useState<ShapefileStatus>('PENDING');

  const shouldZoomFields = useRef<boolean>(false);

  const [layouts, setLayouts] = useState<any[] | undefined>([]);

  const [producerData, setProducerData] = useState<any>(null);

  const [wizardStep, setWizardStep] = useState<'PADDOCK' | 'FIELD' | undefined>(undefined);

  const [loading, setLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [loadingVersions, setLoadingVersions] = useState(false);
  const [changingVersion, setChangingVersion] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const loadingJourney = useRef<boolean>(false);
  const [journey, setJourney] = useState<Journey>();
  const [showCloserFields, setShowCloserFields] = useState<boolean>(true);

  const [estimate, setEstimate] = useState<any>();
  const [enableFinalize, setEnableFinalize] = useState<boolean>(false);
  const [contractPaymentOption, setContractPaymentOption] = useState<string>();

  const FieldService = useFieldService({
    setMode,
    mode,
    shouldZoomFields,
    draw,
    map,
    currentVersion,
    journey,
    producerData,
    setShowCloserFields,
    showCloserFields,
    country,
    contractPaymentOption,
  });
  const EventService = useEventService(FieldService.paddocks, state, setState, currentVersion, map, journey);
  const HerdService = useHerdService(EventService.events, FieldService.paddocks, state, setState, currentVersion, map, journey);
  const StructureService = useStructureService(
    FieldService.paddocks,
    state,
    setState,
    currentVersion?.startDate,
    currentVersion?.endDate,
    map,
    journey,
  );

  useEffect(() => {
    setLoading(FieldService.loadingFields || loadingVersions || initialLoad);
  }, [loadingVersions, FieldService.loadingFields, initialLoad]);

  const isChannelPartner = useSelector((state: any) => {
    return !!state.user.userData.channelPartnerId;
  });

  const getJourney = async (journeyId: string) => {
    if (journeyId.length > 12) {
      const result = await API.get(`${BASE_URL}/base/growers/leads/${journeyId}`);
      setProducerData(result.data);
      updateCompanyAndCountry(result.data?.Company ?? '--', result.data?.country || result.data?.Country);

      const res = await API.post(`${BASE_URL}/base/producer`, { leadId: result.data.leadId, opportunityId: result.data.opportunityId });
      const journeyRes = {
        ...res.data,
        id: res.data.journeyId,
      };
      setJourney(journeyRes);

      const queryParams: string[] = [];
      searchParams.forEach((value, key) => {
        queryParams.push(`${key}=${value}`);
      });
      navigate(`/v2/fields/${journeyRes.id}/${StateUrl[state]}?${queryParams.join('&')}`, { replace: true });
    } else {
      const journeyRes = await API.get(`${BASE_URL}/base/producer/journey/${journeyId}`);
      const res = await API.get(`${BASE_URL}/base/growers/leads/${journeyRes.data.opportunityId ?? journeyRes.data.leadId}`);
      setProducerData(res.data);
      setJourney(journeyRes.data);
      updateCompanyAndCountry(res.data?.Company ?? '--', res.data?.country || res.data?.Country);
    }
  };

  useEffect(() => {
    if (!journeyId || journey?.id === journeyId || loadingJourney.current) return;
    loadingJourney.current = true;

    getJourney(journeyId).then(() => {
      loadingJourney.current = false;
    });
  }, [journeyId]);

  const updateCompanyAndCountry = (companyName, country) => {
    setCompanyName(companyName ?? '--');
    if (country) {
      const map: any = {
        'United States': 'us',
        Brazil: 'br',
      };
      setCountry(map[country] || 'us');
    }
  };

  function getVersions() {
    setLoadingVersions(true);
    return API.get(`${FIELD_URL}/field-service/paddocks/layout-group`, { journeyId: journey?.id })
      .then((res) => {
        const ordered = res?.data?.sort((a: FieldLayout, b: FieldLayout) => {
          return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
        });
        setLayouts(ordered || []);
        setLoadingVersions(false);
        return res;
      })
      .catch((e) => {
        console.log(e);
        setLoadingVersions(false);
      });
  }

  function producerFinishedDataCollection(status, layout) {
    return (
      (status.shapefileStatus === 'APPROVED' && !layout && producerData.boundariesSynced) ||
      ['In Support', 'Ongoing Support', 'GST QA', 'Contract QA'].indexOf(producerData.caseStatus) >= 0 ||
      (producerData.boundariesSynced && producerData?.caseStatus?.toLowerCase() === 'Data Entry'.toLowerCase())
    );
  }

  function setGrazingState() {
    if (hideGrazing) {
      setState('OVERVIEW_V2');
    } else {
      setState(fieldId ? 'DETAILS' : 'OVERVIEW');
    }
  }

  function setFirstSetupState() {
    setState('FIRST_SETUP');
    setWizardStep('FIELD');
  }

  useEffect(() => {
    if (!journey) return;
    Promise.all([getVersions(), FieldService.getFields(), getShapefileStatus()])
      .then((res) => {
        const fields = res[1]?.fields;
        const layout = fields?.length && fields[0]?.layout;
        const statusRes = res[2];
        setShapefileStatus(statusRes.shapefileStatus);
        if (layout) {
          setCurrentVersion({
            name: layout.name,
            startDate: layout.startDate,
            endDate: layout.endDate,
          });
        }
        if (country === 'br' && brFinalizeBoundaries) {
          if (['In Support', 'Ongoing Support', 'GST QA', 'Contract QA'].indexOf(producerData?.caseStatus) !== -1) {
            setState('OVERVIEW_V2');
            setShowCloserFields(false);
          } else {
            checkIfEstimateAndContractExists();
            setState('FIRST_SETUP');
            setWizardStep('FIELD');
          }
        } else {
          setEnableFinalize(true);
          if (isChannelPartner && cpBoundaryTool) {
            setGrazingState();
          } else if (shouldLdAllowBoundaryEdit) {
            setFirstSetupState();
          } else if (
            country === 'br' &&
            ['In Support', 'Ongoing Support', 'GST QA', 'Contract QA', 'Data Entry'].indexOf(producerData?.caseStatus) !== -1
          ) {
            setState('OVERVIEW_V2');
            setShowCloserFields(false);
          } else if (
            producerData?.caseStatus === 'Document & Data Collection' ||
            (!producerData.boundariesSynced && producerData?.caseStatus?.toLowerCase() === 'Data Entry'.toLowerCase())
          ) {
            setFirstSetupState();
          } else if (producerFinishedDataCollection(statusRes, layout)) {
            setGrazingState();
          } else if (fields?.length && layout) {
            setGrazingState();
          } else if ((fields?.length && !layout) || !fields?.length) {
            setFirstSetupState();
          }
        }
        FieldService.setLoadingFields(false);
      })
      .finally(() => setInitialLoad(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journey]);

  const getCurrentVersionInfo = useCallback(async () => {
    if (!journey) return;
    setChangingVersion(true);
    clearAllEntities();
    let newBoundaries;
    if (state !== 'SECOND_SETUP') newBoundaries = await FieldService.getFields(true);
    if (!hideGrazing) {
      await Promise.all([
        HerdService.getHerds(newBoundaries?.paddocks),
        hideStructuresAndEvents ? Promise.resolve() : StructureService.getStructures(undefined, newBoundaries?.paddocks),
        hideStructuresAndEvents ? Promise.resolve() : EventService.getEvents(undefined, newBoundaries?.paddocks),
      ]);
    }
    setChangingVersion(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersion]);

  useEffect(() => {
    if (currentVersion && !initialLoad) {
      getCurrentVersionInfo();
    }
  }, [currentVersion, initialLoad, getCurrentVersionInfo]);

  function clearAllEntities() {
    HerdService.clearHerds();
    StructureService.clearStructures();
    EventService.clearEvents();
  }

  function removeAllMarkers() {
    for (const herd of HerdService.herds) {
      if (herd.marker) herd.marker.remove();
    }
    for (const event of EventService.events) {
      if (event.marker) event.marker.remove();
    }
    for (const structure of StructureService.structures) {
      if (structure.marker) structure.marker.remove();
    }
  }

  useEffect(() => {
    shouldZoomFields.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId, map]);

  const centerMap = useCallback(() => {
    if (map) {
      let newCenter = getPaddedBBox(
        turf.bbox(turf.featureCollection(FieldService.fields.map((f) => f.properties?.processedProperties?.original.boundaries ?? f))),
      );
      if (fieldId) {
        const field = FieldService.fields.find((field) => field.id === fieldId);
        if (field)
          newCenter = getPaddedBBox(turf.bbox(turf.featureCollection([field.properties?.processedProperties?.original.boundaries ?? field])));
      }
      map.fitBounds([newCenter[0], newCenter[1], newCenter[2], newCenter[3]], fitBoundsOption);
    }
  }, [map, FieldService.fields, fieldId]);

  function centerMapTargeted(fields: Array<Feature>) {
    const newCenter = getPaddedBBox(
      turf.bbox(turf.featureCollection(fields.map((f) => f.properties?.processedProperties?.original.boundaries ?? f))),
    );
    map?.fitBounds([newCenter[0], newCenter[1], newCenter[2], newCenter[3]], fitBoundsOption);
  }

  //Control map zoom
  useEffect(() => {
    if (map && shouldZoomFields.current && FieldService.fields.length) {
      shouldZoomFields.current = false;
      centerMap();
    }
  }, [map, FieldService.fields, fieldId, centerMap]);

  function restartDraw() {
    // @ts-ignore
    draw?.draw.deleteAll();
  }
  function restartPaddockBoundary() {
    restartDraw();
    let field = FieldService.fields.find((field) => field.properties && field.properties['editing']);
    if (!field) {
      field = FieldService.fields.find((field) => field.properties && field.properties['selected']);
    }
    if (!field) {
      return;
    }
    FieldService.restartPaddock(field);
  }

  function setMode(mode: string) {
    if (draw) {
      // @ts-ignore
      if (draw?.draw.getMode() === mode) {
        return;
      }

      // @ts-ignore
      const features = draw?.draw.getAll()?.features;
      const featureIds = (features ?? []).map((feature: any) => feature.id);

      const options: any = { setFinishedDrawingField: FieldService.setFinishedDrawingField };

      if (mode !== MAP_MODES.CIRCULAR_BORDER) {
        if ([MAP_MODES.DIRECT_SELECT, MAP_MODES.INNER_BORDER, MAP_MODES.POINT_DELETION].indexOf(mode) !== -1) {
          options.featureId = featureIds[featureIds.length - 1];
        } else if (featureIds.length > 1) {
          options.featureIds = featureIds;
        } else {
          options.featureId = featureIds[0];
        }
      }
      if ([MAP_MODES.DIRECT_SELECT, MAP_MODES.STATIC, MAP_MODES.SIMPLE_SELECT].includes(mode)) {
        map!.getCanvas().style.cursor = 'unset';
      } else {
        map!.getCanvas().style.cursor = 'crosshair';
      }

      // @ts-ignore
      draw.draw.changeMode(mode, options);
      setCurrentMode(mode);
    }
  }

  function hasBoundaries() {
    // @ts-ignore
    const selected = draw?.draw.getAll();
    return !!selected?.features[0];
  }

  function currentVersionIsSaved(): boolean {
    if (layouts) {
      return layouts?.find((layout) => layout.name === currentVersion?.name);
    }
    return false;
  }

  function setNotSavedPaddockVersion() {
    FieldService.setPaddocks((paddocks) => {
      for (const field of FieldService.fields) {
        if (!paddocks[field.id!] || paddocks[field.id!].length === 0) {
          paddocks[field.id!] = [
            {
              ...field,
              id: v4(),
              properties: {
                ...field.properties,
                fieldId: field.id,
                area: field.properties?.area,
                areaHectare: field.properties?.areaHectare,
                editing: undefined,
                selected: undefined,
                situation: undefined,
                practiceType: undefined,
                practices: undefined,
              },
            },
          ];
        } else {
          paddocks[field.id!] = paddocks[field.id!].map((paddock) => {
            return {
              ...paddock,
              id: v4(),
            };
          });
        }
      }

      return {
        ...paddocks,
      };
    });
  }

  async function saveVersion(): Promise<any> {
    const body: any[] = [];
    for (const field of FieldService.fields) {
      if (FieldService.paddocks[field.id!].length > 0) {
        body.push({
          fieldId: field.id,
          name: currentVersion?.name ?? '',
          startDate: currentVersion?.startDate,
          endDate: currentVersion?.endDate,
          growerId: journey?.leadId ?? journey?.opportunityId,
          journeyId: journey?.id,
          paddocks: FieldService.paddocks[field.id!].map((paddock: Feature) => {
            return {
              id: paddock.id,
              fieldId: paddock.properties!['fieldId'],
              name: paddock.properties!['name'],
              area: paddock.properties!['area'],
              areaHectare: paddock.properties!['areaHectare'],
              status: 'VISIBLE',
              boundary: paddock,
            };
          }),
        });
      }
    }

    return await API.post(`${FIELD_URL}/field-service/paddocks/layouts`, body).then(async (res) => {
      if (!currentVersionIsSaved()) {
        await getCurrentVersionInfo();
      } else {
        const newBoundaries = await FieldService.getFields();
        if (newBoundaries) {
          HerdService.herds.forEach((herd) => {
            HerdService.handleHerdMarkers(herd, newBoundaries.paddocks);
          });
          HerdService.filterHerdMarkers();
        }
      }

      getVersions();
      return res;
    });
  }

  function getLastPossibleVersionEndDate() {
    return API.get(`${FIELD_URL}/field-service/paddocks/layouts/last-date`, { journeyId: journey?.id }).then((res) => res.data.lastDate);
  }

  function endCurrentVersion(date: Date) {
    API.put(`${FIELD_URL}/field-service/paddocks/layouts/end-current`, {
      journeyId: journey?.id,
      layoutName: currentVersion!.name,
      endDate: date,
    }).then(() => {
      getVersions();
      setCurrentVersion({
        name: currentVersion!.name,
        startDate: currentVersion!.startDate,
        endDate: date,
      });
    });
  }

  function getShapefileStatus() {
    return API.get(`${FIELD_URL}/field-service/shapefiles/status?journeyId=${journey?.id}`)
      .then((res) => res.data)
      .catch();
  }

  async function checkIfEstimateAndContractExists() {
    const estimatesResponse = await getEstimate();
    if (!estimatesResponse) {
      return;
    }

    const contractResponse = await API.get(`${CONTRACT_URL}/contract-service/contract/${journey?.id}/${estimatesResponse.id}`);
    if (!contractResponse.data) {
      return;
    }
    setContractPaymentOption(contractResponse.data.data.paymentOption);
    setEnableFinalize(true);
  }

  async function getEstimate() {
    const estimatesResponse = await API.get(`${ESTIMATE_URL}/estimate-service/estimates/sync-state/${journey?.id}`);
    if (estimatesResponse.data) {
      setEstimate(estimatesResponse.data);
    }
    return estimatesResponse.data;
  }

  async function calculateCarbonEstimate() {
    const estimatesResponse = await API.put(`${ESTIMATE_URL}/estimate-service/calculator/calculate-all`, {
      scenarios: estimate.data,
      country: country === 'br' ? 'BR' : 'US',
    });
    return estimatesResponse.data;
  }

  function getSyncAvailabilityEstimate() {
    const shortCodeToPracticeLookup: any = {
      NT: 'noTillage',
      IT: 'reducedTillage',
      NRR: 'nitrogenReduction',
      NO: 'nitrogenOptimization',
      LA: 'legume',
      CC: 'coverCropping',
      RPF: 'fertilization',
      RPIG: 'grazing',
      RPBS: 'seeding',
      ICL1: 'ICL',
      ICL2: 'ICL',
      CP: 'coverPerennial',
      CA: 'coverAnnual',
      IN: 'inoculant',
    };

    const clonedFields = FieldService.fields.map((field) => {
      return { ...field };
    });
    const areaIdentifier = country === 'br' ? 'areaHectare' : 'area';
    for (const scenario of estimate.data) {
      const fieldIndex = clonedFields.findIndex((field) => field.id === scenario.id || field.properties?.[areaIdentifier] === scenario.area);
      if (fieldIndex === -1) {
        return false;
      }

      const field = clonedFields[fieldIndex];
      clonedFields.splice(fieldIndex, 1);

      if (scenario.practices.length !== (field.properties?.practices.length ?? 0)) {
        return false;
      }
      for (const practice of scenario.practices) {
        if (field.properties?.practices.indexOf(shortCodeToPracticeLookup[practice.shortCode]) === -1) {
          return false;
        }
      }
    }
    return true;
  }

  const invalidSteps = (stepIndex) => {
    const invalidField =
      !producerData.opportunityId ||
      FieldService.fieldsDrawingInvalid ||
      FieldService.fieldsIntersecting ||
      FieldService.fields.length === 0 ||
      !!FieldService.overlaps?.length ||
      FieldService.overlaps === null ||
      !enableFinalize;
    const invalidFieldProcessing = FieldService.fields.length === 0;

    const invalid = enableAsyncFieldProcessing && country === 'us' && !FieldService.ineligibleAreasFinalized ? invalidFieldProcessing : invalidField;

    const config: Record<number, boolean> = {
      0: invalid,
      1: FieldService.paddocksInvalid,
      2: HerdService.herdsInvalid,
    };
    return config[stepIndex];
  };

  return {
    journey,
    shapefileStatus,
    map,
    setMap,
    centerMap,
    centerMapTargeted,

    loading,
    state,
    setState,
    companyName,
    producerData,
    country,

    mode,
    setDraw,
    restartDraw,
    setMode,

    showConfirmationModal,
    setShowConfirmationModal,
    showUploadModal,
    setShowUploadModal,
    restartPaddockBoundary,

    currentVersion,
    setCurrentVersion,
    removeAllMarkers,

    hasBoundaries,

    layouts,
    setNotSavedPaddockVersion,
    saveVersion,
    getLastPossibleVersionEndDate,
    endCurrentVersion,
    changingVersion,

    estimate,
    enableFinalize,
    contractPaymentOption,
    getEstimate,
    calculateCarbonEstimate,
    getSyncAvailabilityEstimate,

    ...FieldService,
    ...HerdService,
    ...EventService,
    ...StructureService,

    wizardStep,
    invalidSteps,
    setWizardStep,
    showCloserFields,
  };
};

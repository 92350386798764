import { API } from '../common/api';
import { FIELD_URL } from '../common/env';
import { Journey } from '../../pages/customer-profile/types/GrowerService';
import {
  DCEventTypes,
  EventsSuccess,
  IDCEventType,
  IDCHarvestEvent,
  IDCPlantingEvent,
  IDCTillageEvent,
  IEvents,
  IEventsToDelete,
} from '../../pages/customer-profile/components/Tabs/DataCollectionTab/interfaces';
import { v4 as uuidv4 } from 'uuid';
import { ErroredEventMapping, getDateText } from '../../pages/customer-profile/components/Tabs/DataCollectionTab/utils/utils';
import { Field } from '../../pages/customer-profile/types/Field';
import { cloneDeep } from 'lodash';

const getEventsConfiguration = async (journeyId: string): Promise<{ containsEvents: boolean; events: DCEventTypes } | void> => {
  return await API.get(`${FIELD_URL}/field-service/data-collection/${journeyId}`)
    .then((res) => {
      const events = {} as DCEventTypes;
      let containsEvents = false;
      res.data.forEach((eventTypes) => {
        events[eventTypes.name] = {
          ...eventTypes,
          events: {},
        };
        eventTypes.events.forEach((event) => {
          events[eventTypes.name].events[event.id] = getEventWithoutNullValues(event);
          let otherEvents = undefined;
          if (eventTypes.name === 'Planting' || 'Harvest') {
            otherEvents = res.data.find((eventType) => eventType.name === 'Harvest')?.events;
          }
          if (eventTypes.name === 'Harvest') {
            otherEvents = res.data.find((eventType) => eventType.name === 'Planting')?.events.find((e) => e.id === event.plantingId);
          }
          events[eventTypes.name].events[event.id].erroredEvent = ErroredEventMapping[eventTypes.name](event, otherEvents);
        });
        containsEvents = containsEvents || !!eventTypes.events.length;
      });
      return { events, containsEvents };
    })
    .catch(() => {
      throw Error('Error while retrieving events');
    });
};

const getImplementationYear = async (journeyId: string): Promise<{ journeyId: string; opportunityId: string; implementationYear: number } | void> => {
  return await API.get(`${FIELD_URL}/field-service/data-collection/implementation-year/${journeyId}`)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      throw Error('Error while retrieving implementation year');
    });
};

const getFarmInformation = async (
  journeyId: string,
): Promise<{ opportunityId: string; journeyId: string; implementationYear: number; grazingSeason: { startDate: Date; endDate: Date } } | void> => {
  return await API.get(`${FIELD_URL}/field-service/data-collection/farm-information/${journeyId}`)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      throw Error('Error while retrieving farm information');
    });
};

const upsertImplementationYear = async (body: {
  journeyId: string;
  opportunityId: string;
  implementationYear: number;
}): Promise<{ journeyId: string; opportunityId: string; implementationYear: number } | void> => {
  return await API.post(`${FIELD_URL}/field-service/data-collection/implementation-year`, body)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      throw Error('Error while retrieving implementation year');
    });
};

const upsertGrazingSeason = async (body: {
  journeyId: string;
  opportunityId: string;
  startDate: Date;
  endDate: Date;
}): Promise<{ journeyId: string; opportunityId: string; startDate: Date; endDate: Date } | void> => {
  return await API.post(`${FIELD_URL}/field-service/data-collection/grazing-season`, body)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      throw Error('Error while retrieving farm information');
    });
};

const getStateLocationRelations = async (): Promise<Array<{ state: string; stateCode: string; location: string }> | void> => {
  return await API.get(`${FIELD_URL}/field-service/data-collection/state-locations`)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      throw Error('Error while retrieving state location relations');
    });
};

const addEditedEventOnFullObject = (eventTypes: DCEventTypes, editedEvents: DCEventTypes) => {
  const eventTypesCopy = cloneDeep(eventTypes);
  const fieldsAffected = new Set<string>();
  for (const eventType of Object.values(editedEvents)) {
    for (const updatedEvent of Object.values(eventType.events)) {
      eventTypesCopy[eventType.name].events[updatedEvent.id] = updatedEvent;
      fieldsAffected.add(updatedEvent.fieldId);
    }
  }

  const allAffectedEvents: DCEventTypes = cloneDeep(editedEvents);

  for (const eventType of Object.values(eventTypesCopy) as IDCEventType[]) {
    for (const event of Object.values(eventType.events)) {
      if (fieldsAffected.has(event.fieldId)) {
        allAffectedEvents[eventType.name].events[event.id] = event;
      }
    }
  }

  return allAffectedEvents;
};

const treatedEventTypes = (eventTypes, filterNewEvents: boolean = false) => {
  const eventTypesCopy = JSON.parse(JSON.stringify(eventTypes));
  for (const eventType of Object.values(eventTypesCopy) as IDCEventType[]) {
    for (const event of Object.values(eventType.events)) {
      if (filterNewEvents && event.newEvent) {
        delete eventType.events[event.id];
      } else {
        delete event.createdAt;
        delete event.updatedAt;
        delete event.newEvent; //Remove flag used for display purposes
      }
    }
  }
  return eventTypesCopy;
};

const copyEventTypes = (eventTypes: DCEventTypes, eventsTypesEdited: DCEventTypes, fieldIds: string[]) => {
  const eventTypesCopy = JSON.parse(JSON.stringify(eventTypes));
  for (const eventTypeEdited of Object.values(eventsTypesEdited) as IDCEventType[]) {
    const originalEventType = (Object.values(eventTypesCopy) as IDCEventType[]).find((evType) => evType.name === eventTypeEdited.name);

    for (let event of Object.values(eventTypeEdited.events)) {
      if (event.copy) {
        fieldIds.forEach((fieldId) => {
          const id = uuidv4();
          originalEventType!.events[id] = {
            ...event,
            id,
            fieldId,
            createdAt: new Date(),
            updatedAt: new Date(),
          };

          if (originalEventType!.name === 'Planting') {
            const harvestEventType = eventTypesCopy.Harvest as IDCEventType;
            const harvestEventValues = Object.values(harvestEventType.events) as IDCHarvestEvent[];
            if (harvestEventValues.length) {
              harvestEventValues
                .filter((e) => e.plantingId === event.id)
                .forEach((he) => {
                  const hId = uuidv4();
                  harvestEventType.events[hId] = {
                    ...he,
                    id: hId,
                    fieldId,
                    plantingId: id,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                  };
                });
            }
          }
        });
      }
    }
  }
  return eventTypesCopy;
};

const saveEvents = async (producerEvents: DCEventTypes, journey: Journey, eventsDeleted: IEventsToDelete[] = [], eventFieldId) => {
  return await API.post(`${FIELD_URL}/field-service/data-collection`, {
    journeyId: journey.id,
    opportunityId: journey.opportunityId,
    producerEvents: Object.values(producerEvents).map((eventType) => {
      const eventTypeDeleted = eventsDeleted.find((e) => e.name === eventType.name)?.eventIds ?? [];
      const events = Object.values(eventType.events).filter((e) => 
        !eventTypeDeleted.includes(e.id) && (!eventFieldId || e.fieldId === eventFieldId)
      );
      return {
        ...eventType,
        constraints: null,
        events: events,
      };
    }),
  }).then((res) => {
    const savedEvents: EventsSuccess = res.data.eventsSuccess;
    for (const eventType of Object.keys(savedEvents)) {
      for (const event of Object.values(savedEvents[eventType]) as IEvents[]) {
        let otherEvents: IDCPlantingEvent | IDCHarvestEvent[] | undefined = undefined;
        if (savedEvents['Harvest'] && eventType === 'Planting') {
          otherEvents = Object.values(savedEvents['Harvest']) as IDCHarvestEvent[];
        }
        if (eventType === 'Harvest') {
          otherEvents = savedEvents['Planting'][(event as IDCHarvestEvent).plantingId] as IDCPlantingEvent;
        }
        event.erroredEvent = ErroredEventMapping[eventType](event, otherEvents);
        savedEvents[eventType][event.id] = getEventWithoutNullValues(event);
      }
    }
    return res;
  });
};

const deleteEvents = async (eventsToDelete: IEventsToDelete[]) => {
  if (eventsToDelete.some((eventType) => eventType.eventIds.length)) {
    const body = { eventsToDelete: eventsToDelete.filter((e) => e.eventIds.length).map(({ fieldId, ...eventWithoutFieldId }) => eventWithoutFieldId) };
    return await API.post(`${FIELD_URL}/field-service/data-collection/delete-events`, body);
  }
};

function getEventWithoutNullValues(event) {
  //null values are breaking the form
  const newEvent = {};
  Object.keys(event).forEach((k) => {
    newEvent[k] = event[k] ? event[k] : '';
  });
  return newEvent;
}

function checkInSeasonTillageEvents(
  producerEvents: DCEventTypes,
  tillageTypes: Record<string, any>,
  eventsToDelete: IEventsToDelete[],
  fields: Field[],
) {
  const outOfSeasonTillages = {};
  const tillageEvents = Object.values(producerEvents['Tillage'].events) as IDCTillageEvent[];
  for (const tillage of tillageEvents) {
    const tillageDeleted = eventsToDelete?.find((ep) => ep.name === 'Tillage')?.eventIds?.includes(tillage.id);
    //Check if it's a valid Tillage event
    if (!tillage.tillageTypeId || !tillage.tillageDate || tillageDeleted) continue;
    const tillageType = tillageTypes.find((tillageType) => tillageType.id === tillage.tillageTypeId);
    if (!tillageType || tillageType.inSeason) continue;

    const tillageTime = new Date(tillage.tillageDate).getTime();
    const validPlantings = (Object.values(producerEvents['Planting'].events) as IDCPlantingEvent[]).filter(
      (e: IDCPlantingEvent) =>
        e.fieldId === tillage.fieldId && !!e.plantingDate && !eventsToDelete?.find((ep) => ep.name === 'Planting')?.eventIds?.includes(e.id),
    );

    //Check if there is any valid planting and harvest event
    for (const planting of validPlantings as IDCPlantingEvent[]) {
      const harvestWithinTillage = (Object.values(producerEvents['Harvest'].events) as IDCHarvestEvent[])
        .filter(
          (harvest) => harvest.plantingId === planting.id && !eventsToDelete?.find((ep) => ep.name === 'Harvest')?.eventIds?.includes(harvest.id),
        )
        .sort((h1, h2) => {
          const h1Time = h1.harvestDate ? new Date(h1.harvestDate).getTime() : 0;
          const h2Time = h2.harvestDate ? new Date(h2.harvestDate).getTime() : 0;
          return h1Time - h2Time;
        })
        ?.find((harvest) => {
          return (
            harvest.harvestDate && tillageTime >= new Date(planting.plantingDate).getTime() && tillageTime <= new Date(harvest.harvestDate).getTime()
          );
        })?.harvestDate;
      if (!harvestWithinTillage) continue;
      const tillageDate = getDateText(tillage.tillageDate);
      const plantingDate = getDateText(planting.plantingDate);
      const harvestDate = getDateText(harvestWithinTillage);
      const timeframeId = `${plantingDate} - ${harvestDate}`;
      outOfSeasonTillages[timeframeId]?.tillages
        ? outOfSeasonTillages[timeframeId].tillages.push(tillageDate)
        : (outOfSeasonTillages[timeframeId] = {
            plantingTimeFrame: [plantingDate, harvestDate],
            tillages: [tillageDate],
            fieldName: fields.find((fd) => fd.id === planting.fieldId)?.name,
          });
    }
  }
  return outOfSeasonTillages;
}

async function getModelInputDefaults() {
  return await API.get(`${FIELD_URL}/field-service/data-collection/model-input-defaults`);
}

export const DataCollectionService = {
  getEventsConfiguration,
  getImplementationYear,
  upsertImplementationYear,
  getFarmInformation,
  upsertGrazingSeason,
  getStateLocationRelations,
  getModelInputDefaults,
  deleteEvents,
  saveEvents,
  treatedEventTypes,
  copyEventTypes,
  checkInSeasonTillageEvents,
  addEditedEventOnFullObject,
};

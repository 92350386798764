import { AnyAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { getFilteredLeads } from '../../service/leads/leads-list-transformer';

// The leads here are legacy, new functionalities should use/implement leads in the lead-reducer (store)
const initState: any = {
  growers: undefined,
  growerListState: { lifeCycle: 'NONE' },
  contractInfo: undefined,
  contractInfoState: { lifeCycle: 'NONE', docusignStatus: 'UNINITIALIZED' },
  contractType: undefined,
  leads: undefined,
  leadsByName: undefined,
  fullLeads: undefined,
  contractId: undefined,
  leadListState: { lifeCycle: 'NONE' },
  leadListParams: undefined,
  guardrailResults: undefined,
  guardrailResultsState: { lifeCycle: 'NONE' },
  salesApprovalRequestState: { lifeCycle: 'NONE' },
  opportunityId: undefined,
  contractRegion: '',
  createdBy: '',
  leadsFilterStatusSelected: '',
  leadsFiltersearchString: '',
  leadsSortSelected: { field: 'createdAt', direction: 'descending' },
  estimateName: '',
  opportunityIdState: { lifeCycle: 'NONE' },
  channelPartners: undefined,
  channelPartnerState: { lifeCycle: 'NONE' },
  riskAssessment: undefined,
  riskAssessmentState: { lifeCycle: 'NONE' },
  defaultAvailablePractices: undefined,
  activeContractVersion: undefined,
  realActiveContractVersion: undefined, //change this to be activeContractVersion, saving the whole activeContractVersion object
  contractVersion: undefined,
};

const getFiltersFromState = (state: any) => {
  return { status: state.leadsFilterStatusSelected, searchString: state.leadsFiltersearchString };
};

const reducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case 'GET_LEAD_LIST_REQUESTED': {
      return {
        ...state,
        leadListState: { lifeCycle: 'PENDING' },
        leadListParams: action.params,
      };
    }
    case 'RESET_NOTE_TO_OA_REQUESTED': {
      const newContractInfo = { ...state.contractInfo };
      newContractInfo.notesSection = action.payload.originalNotes;
      return {
        ...state,
        contractInfo: newContractInfo,
      };
    }
    case 'GET_LEAD_LIST_SUCCEEDED': {
      //Prevent overwriting calls to get lead list
      if (action.params !== state.leadListParams) return { ...state };

      const currentList = !state.leadsFilterSearchString
        ? action.payload
        : action.payload.filter((a) => a.name.toLowerCase().includes(state.leadsFilterSearchString.toLowerCase()));

      return {
        ...state,
        leads: getFilteredLeads(getFiltersFromState(state), state.leadsSortSelected, currentList),
        leadsByName: currentList,
        fullLeads: action.payload,
        leadListState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'CONTRACT_PAGE_DISMOUNTED': {
      return {
        growers: undefined,
        growerListState: { lifeCycle: 'NONE' },
        contractInfo: undefined,
        contractInfoState: { lifeCycle: 'NONE', docusignStatus: 'UNINITIALIZED' },
        contractType: undefined,
        leads: undefined,
        fullLeads: undefined,
        contractId: undefined,
        leadListState: { lifeCycle: 'NONE' },
        leadListParams: undefined,
        guardrailResults: undefined,
        guardrailResultsState: { lifeCycle: 'NONE' },
        salesApprovalRequestState: { lifeCycle: 'NONE' },
        opportunityId: undefined,
        contractRegion: '',
        createdBy: '',
        leadsFilterStatusSelected: '',
        leadsSortSelected: { field: 'createdAt', direction: 'descending' },
        estimateName: '',
        opportunityIdState: { lifeCycle: 'NONE' },
        channelPartners: undefined,
        channelPartnerState: { lifeCycle: 'NONE' },
        riskAssessment: undefined,
        riskAssessmentState: { lifeCycle: 'NONE' },
        defaultAvailablePractices: undefined,
        activeContractVersion: undefined,
        realActiveContractVersion: undefined,
        contractVersion: undefined,
      };
    }
    case 'GET_LEAD_LIST_ERROR': {
      return {
        ...state,
        leadListState: { lifeCycle: 'ERROR', error: action.payload },
      };
    }
    case 'GET_LEADS_BY_CONTRACT_STATUS_REQUESTED': {
      return {
        ...state,
        leadListState: { lifeCycle: 'PENDING' },
        leadListParams: action.params,
      };
    }
    case 'GET_LEADS_BY_CONTRACT_STATUS_SUCCEEDED': {
      //Prevent overwriting calls to get lead list
      if (action.params !== state.leadListParams) return { ...state };

      const currentList = !state.leadsFilterSearchString
        ? state.fullLeads
        : state.fullLeads.filter((a) => a.name.toLowerCase().includes(state.leadsFilterSearchString.toLowerCase()));
      return {
        ...state,
        leads: getFilteredLeads(getFiltersFromState(state), state.leadsSortSelected, currentList),
        fullLeads: action.payload,
        leadsByName: currentList,
        leadListState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'GET_LEADS_BY_CONTRACT_STATUS_ERROR': {
      return {
        ...state,
        leadListState: { lifeCycle: 'ERROR', error: action.payload },
      };
    }
    case 'GET_GROWER_LIST_REQUESTED': {
      return {
        ...state,
        growerListState: { lifeCycle: 'PENDING' },
      };
    }
    case 'GET_GROWER_LIST_SUCCEEDED': {
      return {
        ...state,
        growers: action.payload,
        growerListState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'GET_GROWER_LIST_ERROR': {
      return {
        ...state,
        growerListState: { lifeCycle: 'ERROR', error: action.payload },
      };
    }
    case 'GET_CONTRACT_INFO_REQUESTED': {
      // const emptyFormInfo = ContractService.getContractInfo(null, null);
      return {
        ...state,
        contractId: undefined,
        // contractInfo: emptyFormInfo.data,
        contractInfoState: { lifeCycle: 'INFO_PENDING' }, // The lifecycle info_pending here is used to differentiate between getting the info to prefill the form and submitting the info to docusign
        salesApprovalRequestState: { lifeCycle: 'NONE' },
      };
    }
    case 'GET_CONTRACT_INFO_SUCCEEDED': {
      return {
        ...state,
        contractInfoState: {
          lifeCycle: 'INFO_SUCCESS',
          docusignStatus: action.payload.docusignStatus,
        },
        contractInfo: {
          ...action.payload.contractInfo,
        },
        contractVersionId: action.payload.contractVersionId,
        createdBy: action.payload.createdBy,
        contractRegion: action.payload.contractRegion,
        estimateName: action.payload.estimateName,
        contractId: action.payload.contractId,
        contractType: action.payload.contractType,
        approvedBy: action.payload.approvedBy,
        docusignUpdate: action.payload.docusignUpdate,
      };
    }
    case 'CONTRACT_INFO_UPDATED': {
      return {
        ...state,
        originalNotes: action.payload.notesSection,
        contractInfo: cloneDeep(action.payload),
      };
    }
    case 'GET_CONTRACT_INFO_ERROR': {
      return {
        ...state,
        contractInfoState: { lifeCycle: 'INFO_ERROR', error: action.payload },
      };
    }
    case 'CARBON_SEQUESTRATION_APPROVAL_REASONS_SUCCESSFUL': {
      return {
        ...state,
        carbonSequestrationApprovalReasons: action.payload,
      };
    }
    case 'AUTO_SAVE_CONTRACT_REQUESTED': {
      return {
        ...state,
        contractInfoState: {
          ...state.contractInfoState,
          lifeCycle: 'SAVE_PENDING',
        },
      };
    }
    case 'AUTO_SAVE_CONTRACT_SUCCEEDED': {
      return {
        ...state,
        contractId: action.payload.id,
        contractInfoState: {
          ...state.contractInfoState,
          docusignStatus: action.payload.docusignStatus,
          lifeCycle: 'SAVE_SUCCESS',
        },
      };
    }
    case 'GET_CREATE_CONTRACT_REQUESTED': {
      return {
        ...state,
        contractInfoState: {
          ...state.contractInfoState,
          lifeCycle: 'SUBMIT_PENDING',
        },
      };
    }
    case 'GET_GUARDRAIL_RESULTS_REQUESTED': {
      return {
        ...state,
        guardrailResultsState: { lifeCycle: 'PENDING' },
      };
    }
    case 'GET_GUARDRAIL_RESULTS_SUCCEEDED': {
      return {
        ...state,
        guardrailResults: action.payload,
        guardrailResultsState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'GET_GUARDRAIL_RESULTS_ERROR': {
      return {
        ...state,
        guardrailResultsState: { lifeCycle: 'ERROR', error: action.payload },
      };
    }
    case 'SALES_APPROVAL_REQUEST_REQUESTED': {
      return {
        ...state,
        contractInfoState: {
          ...state.contractInfoState,
          lifeCycle: 'SUBMIT_PENDING',
        },
        salesApprovalRequestState: { lifeCycle: 'PENDING' },
      };
    }
    case 'SALES_APPROVAL_REQUEST_SUBMITTED': {
      return {
        ...state,
        contractType: action.payload.contractType,
        contractInfoState: {
          lifeCycle: 'SUCCESS',
          docusignStatus: action.payload.docusignStatus,
        },
        salesApprovalRequestState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'REJECTION_BY_SALESPERSON_SUCCEEDED': {
      return {
        ...state,
        contractType: action.payload.contractType,
        contractInfo: action.payload.data,
        contractInfoState: {
          lifeCycle: 'SUCCESS',
          docusignStatus: action.payload.docusignStatus,
        },
        salesApprovalRequestState: { lifeCycle: 'REJECTED' },
      };
    }
    case 'SALES_APPROVAL_REQUEST_ERROR': {
      return {
        ...state,
        salesApprovalRequestState: {
          lifeCycle: 'ERROR',
          error: action.payload,
        },
      };
    }
    case 'SEND_TO_RISK_ASSESSMENT_REQUESTED': {
      return {
        ...state,
        contractInfoState: { ...state.contractInfoState, lifeCycle: 'SUBMIT_PENDING' },
      };
    }
    case 'SEND_TO_RISK_ASSESSMENT_SUCCEEDED': {
      return {
        ...state,
        contractInfo: action.payload.data,
        contractInfoState: {
          ...state.contractInfoState,
          lifeCycle: 'SUCCESS',
          docusignStatus: action.payload.docusignStatus,
        },
      };
    }
    case 'SEND_TO_RISK_ASSESSMENT_ERROR': {
      return {
        ...state,
        contractInfoState: {
          ...state.contractInfoState,
          lifeCycle: 'SUBMIT_ERROR',
          error: action.payload,
        },
      };
    }
    case 'GET_VALID_CHANNEL_PARTNERS_REQUESTED': {
      return {
        ...state,
        channelPartnersState: { lifeCycle: 'PENDING' },
      };
    }
    case 'GET_VALID_CHANNEL_PARTNERS_SUCCEEDED': {
      return {
        ...state,
        channelPartners: action.payload,
        channelPartnersState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'GET_VALID_CHANNEL_PARTNERS_ERROR': {
      return {
        ...state,
        channelPartnersState: {
          lifeCycle: 'ERROR',
          error: action.payload,
        },
      };
    }
    case 'GET_RISK_ASSESSMENT_REQUESTED': {
      return {
        ...state,
        riskAssessmentState: { lifeCycle: 'PENDING' },
      };
    }
    case 'GET_RISK_ASSESSMENT_SUCCEEDED': {
      return {
        ...state,
        riskAssessment: action.payload,
        riskAssessmentState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'LEAD_FILTER_STATUS_SELECTED': {
      const filterStatus = state.leadsFilterStatusSelected === action.payload ? '' : action.payload;
      const filters = {
        ...getFiltersFromState(state),
        status: filterStatus,
      };
      return {
        ...state,
        leads: getFilteredLeads(filters, state.leadsSortSelected, state.leadsByName),
        leadsFilterStatusSelected: filterStatus,
      };
    }
    case 'LEAD_FILTER_STRING_SELECTED': {
      const filterString = action.payload;
      if (state.fullLeads && state.fullLeads.length) {
        const currentList = !filterString
          ? state.fullLeads
          : state.fullLeads.filter((a) => a.name.toLowerCase().includes(filterString.toLowerCase()));
        return {
          ...state,
          leads: getFilteredLeads(getFiltersFromState(state), state.leadsSortSelected, currentList),
          leadsByName: currentList,
          leadsFilterSearchString: filterString,
        };
      }
      return {
        ...state,
        leadsFilterSearchString: filterString,
      };
    }
    case 'SORT_LEAD_BUTTON_CLICKED': {
      const getSortDirection = (oldSort: any, newField: string) => {
        if (oldSort?.field === newField) {
          return oldSort.direction === 'ascending' ? 'descending' : 'ascending';
        }
        return 'ascending';
      };
      const sortBy = {
        field: action.payload,
        direction: getSortDirection(state.leadsSortSelected, action.payload),
      };
      return {
        ...state,
        leadsSortSelected: sortBy,
        leads: getFilteredLeads(getFiltersFromState(state), sortBy, state.leadsByName),
      };
    }
    case 'GET_RISK_ASSESSMENT_ERROR': {
      return {
        ...state,
        riskAssessmentState: {
          lifeCycle: 'ERROR',
          error: action.payload,
        },
      };
    }
    case 'GET_DEFAULT_AVAILABLE_PRACTICES_SUCCEEDED': {
      return {
        ...state,
        defaultAvailablePractices: action.payload.practices,
        activeContractVersion: action.payload.activeContractVersion,
        contractVersion: action.payload.contractVersion,
      };
    }
    case 'GET_DEFAULT_AVAILABLE_PRACTICES_ERROR': {
      return {
        ...state,
        defaultAvailablePractices: [],
        activeContractVersion: undefined,
      };
    }
    case 'GET_ACTIVE_CONTRACT_VERSION_REQUESTED': {
      return {
        ...state,
        realActiveContractVersion: undefined,
      };
    }
    case 'GET_ACTIVE_CONTRACT_VERSION_SUCCEEDED': {
      return {
        ...state,
        realActiveContractVersion: action.payload,
      };
    }
    case 'GET_ACTIVE_CONTRACT_VERSION_ERROR': {
      return {
        ...state,
        realActiveContractVersion: undefined,
      };
    }
    case 'INITIALIZE_CONTRACT_VERSION_REQUESTED': {
      return {
        ...state,
        contractVersion: undefined,
        activeContractVersion: undefined,
        realActiveContractVersion: undefined,
      };
    }
    case 'INITIALIZE_CONTRACT_VERSION_SUCCEEDED': {
      const { contractVersion, activeContractVersion } = action.payload;
      return {
        ...state,
        contractVersion: contractVersion,
        activeContractVersion: contractVersion.active,
        realActiveContractVersion: activeContractVersion,
      };
    }
    case 'INITIALIZE_CONTRACT_VERSION_ERROR': {
      return {
        ...state,
        contractVersion: undefined,
        activeContractVersion: undefined,
        realActiveContractVersion: undefined,
      };
    }
  }
  return state;
};

export default reducer;

import { Box } from 'grommet';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../service/common/env';
import { API } from '../../service/common/api';
import { useEffect, useState } from 'react';

export const MapPage = () => {
  const url = useLocation();
  const navigate = useNavigate();
  const { upsellingBoundaryTool, cpBoundaryTool } = useFlags();
  const [lead, setLead] = useState<any>();
  const params = useParams();
  useEffect(() => {
    if (params.salesforceId) {
      API.get(`${BASE_URL}/base/growers/leads/${params.salesforceId}`).then((result) => {
        setLead(result.data);
      });
    }
  }, [params.salesforceId]);

  const user = useSelector((state: any) => {
    return state.user.userData;
  });

  const isChannelPartner = user?.channelPartnerId;
  if (isChannelPartner && !cpBoundaryTool) {
    navigate(-1);
  }

  const isUSUser = user?.country === 'US';
  const showUpsellingBanner =
    upsellingBoundaryTool &&
    lead?.caseStatus === 'Document & Data Collection' &&
    !!lead?.caseStatusHistory?.find((i) => i.status === 'In Support') &&
    isUSUser;

  return (
    <Box style={{ height: '100%' }}>
      {showUpsellingBanner && (
        <Box
          style={{
            width: '100%',
            height: '68px',
            backgroundColor: '#D03450',
            color: '#fff',
            fontFamily: 'Avenir',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 900,
            lineHeight: '20px',
            letterSpacing: '1.12px',
            justifyContent: 'center',
          }}
        >
          You are on the Upselling flow. Every change made will be considered an addition to all previously inputted information. To edit past data
          collection inputs, please get in touch with the Product Team.
        </Box>
      )}
      <iframe
        frameBorder={'0'}
        title='map-flow'
        name='map-flow'
        height='100%'
        data-hj-allow-iframe='true'
        src={`${process.env['REACT_APP_MAP_URL'] ?? 'https://field-flow.stage.us.agorocarbon.com'}${url.pathname.replace('/fields', '')}${
          url.search
        }`}
      />
    </Box>
  );
};

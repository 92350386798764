import AgoToast from '../../components/Toast/AgoToast';
import { ContractService } from '../../service/contract/contract.service';
import { getStates } from '../../service/common/constants';
import { getActiveCampaigns, getHowDidYouHearOptions, getRepresentativeOptions } from './lead-action';
import { getUserPartnerContracts } from './user-action';
import { getStateLocationRelations } from './data-collection-action';
import { User } from '../reducers/user-reducer';

export const refreshSessionAction: any = (refreshSessionFn: () => Promise<{ accessToken: string }>) => (dispatch: any) => {
  return refreshSessionFn()
    .then((tokens: { accessToken: string }) => {
      dispatch({
        type: 'ACCESS_TOKEN_LOADED',
        payload: { result: tokens.accessToken, refreshSessionFn },
      });
    })
    .catch(() => {
      AgoToast.showToast({
        title: 'Error',
        message: 'Unable to get refresh session, please try again',
        type: 'error',
        toastId: 'refresh-session-error',
      });
    });
};

export const getAppInitializedAction: any =
  (user: User, token: string, refreshSessionFn: () => Promise<{ accessToken: string }>) => (dispatch: any) => {
    dispatch({
      type: 'ACCESS_TOKEN_LOADED',
      payload: { result: token, refreshSessionFn },
    });
    dispatch({
      type: 'GET_USER_SUCCEEDED',
      payload: user,
    });
    ContractService.getSequestrationApprovalReasons()
      .then((result: any) => {
        dispatch({
          type: 'CARBON_SEQUESTRATION_APPROVAL_REASONS_SUCCESSFUL',
          payload: result,
        });
      })
      .then(() => {
        dispatch(getActiveCampaigns());
        dispatch(getHowDidYouHearOptions());
        dispatch(getRepresentativeOptions());
        dispatch(getStateLocationRelations());
        if (user?.channelPartnerId) {
          dispatch(getUserPartnerContracts());
        }
      })
      .then(() => {
        return getStates(user?.country).then((result) => {
          dispatch({
            type: 'AVAILABLE_STATES_SUCCESSFUL',
            payload: { list: result, country: user?.country },
          });
        });
      })
      .then(() => {
        dispatch({
          type: 'APP_INITIALIZED',
        });
      })
      .catch(() => {
        AgoToast.showToast({
          title: 'Initialization failed, API error',
          message: 'Unable to get initialization data',
          type: 'error',
          toastId: 'unable-to-get-initialization-data-error',
        });
      });
  };

import { debounce } from 'lodash';
import AgoToast from '../../components/Toast/AgoToast';
import { ContractService } from '../../service/contract/contract.service';
import { BrazilPersonalInfo, ContractInfo, ContractPracticeList, IContractVersion, IVersionRules } from '../../service/contract/interfaces';
import { CalculatorResult, Practice } from '../../service/estimate/interfaces';
import store from '../index';
import { User } from '../reducers/user-reducer';
import { TFunction, i18n } from 'i18next';
import { applyMask } from '../../pages/contract-creation/utils/utils';
import { ChannelPartner } from '../../service/common/interfaces';
import { defaultVersionRules } from '../../service/contract/utils';

export function treatContractDataFormat(contractData: ContractInfo) {
  if (contractData.country === 'BR') {
    contractData.personalInfoSection = {
      ...contractData.personalInfoSection,
      cpf: applyMask((contractData.personalInfoSection as BrazilPersonalInfo)?.cpf || '', '###.###.###-##'),
      cnpj: applyMask((contractData.personalInfoSection as BrazilPersonalInfo)?.cnpj || '', '##.###.###/####-##'),
    };
  }
}

function formatPartnerBody(personalInfoSection: any) {
  const newPartnerData = personalInfoSection.channelPartner === 'Yes' ? personalInfoSection.channelPartnerData : {};
  return {
    ...personalInfoSection,
    channelPartnerData: newPartnerData,
    channelPartnerName: newPartnerData.value ? newPartnerData.value?.name : 'N/A Direct Sale',
  };
}

export const getDefaultAvailablePracticesAction: any = (contractVersionId: string) => (dispatch: any) => {
  dispatch({
    type: 'GET_DEFAULT_AVAILABLE_PRACTICES_REQUESTED',
  });
  return ContractService.getDefaultAvailablePractices(contractVersionId)
    .then((res: any) => {
      dispatch({
        type: 'GET_DEFAULT_AVAILABLE_PRACTICES_SUCCEEDED',
        payload: res,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_DEFAULT_AVAILABLE_PRACTICES_ERROR',
        payload: err,
      });
    });
};

export const getLeadListAction: any = (user: User, filter: string, showCP: boolean) => (dispatch: any) => {
  dispatch({
    type: 'GET_LEAD_LIST_REQUESTED',
    params: `${filter},${showCP}`,
  });
  return ContractService.getLeadList(user, filter, showCP)
    .then((res: any) => {
      dispatch({
        type: 'GET_LEAD_LIST_SUCCEEDED',
        payload: res,
        params: `${filter},${showCP}`,
      });
      return res;
    })
    .catch((err: any) => {
      // toast is already been shown on contracts service
      dispatch({
        type: 'GET_LEAD_LIST_ERROR',
        payload: err,
      });
    });
};

export const getLeadsByContractStatus: any = (user: User, filter: string, showCP: boolean, status: string) => (dispatch: any) => {
  dispatch({
    type: 'GET_LEADS_BY_CONTRACT_STATUS_REQUESTED',
    params: `${filter},${showCP},${status}`,
  });
  return ContractService.getLeadsByContractStatus(status)
    .then((res) => {
      const leadIdList = res.data.map((lead: any) => lead.growerId);
      return ContractService.getLeadList(user, filter, showCP, leadIdList).then((res) => {
        dispatch({
          type: 'GET_LEADS_BY_CONTRACT_STATUS_SUCCEEDED',
          payload: res,
          params: `${filter},${showCP},${status}`,
        });
      });
    })
    .catch((err) => {
      dispatch({
        type: 'GET_LEADS_BY_CONTRACT_STATUS_ERROR',
        payload: err,
      });
    });
};

export const getGrowerListAction: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_GROWER_LIST_REQUESTED',
  });
  return ContractService.getGrowerList()
    .then((res: any) => {
      dispatch({
        type: 'GET_GROWER_LIST_SUCCEEDED',
        payload: res.data,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_GROWER_LIST_ERROR',
        payload: err,
      });
    });
};
const runDebouncedAutosave = debounce((newInfo: any, metaData: any, dispatch: any) => {
  dispatch({
    type: 'AUTO_SAVE_CONTRACT_REQUESTED',
    payload: {
      newInfo,
      metaData,
    },
  });
  newInfo.personalInfoSection = formatPartnerBody(newInfo.personalInfoSection);
  newInfo.history = getUpdatedContractHistory(newInfo, metaData.docusignStatus);
  return ContractService.saveContractInfo(newInfo, metaData)
    .then((res: any) => {
      dispatch({
        type: 'AUTO_SAVE_CONTRACT_SUCCEEDED',
        payload: res.data,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'AUTO_SAVE_CONTRACT_ERROR',
        payload: err,
      });
    });
}, 500);

export const getUpdatedContractHistory = (contractInfo, docusignStatus, notes = '') => {
  const state: any = store.getState();
  const user = state.user.userData.email;
  const latestStatus = contractInfo.history && contractInfo.history[contractInfo.history.length - 1].docusignStatus;
  const newStatus = {
    docusignStatus,
    notes,
    user,
    createdAt: new Date(),
  };
  if (latestStatus !== docusignStatus) {
    return contractInfo.history ? contractInfo.history.concat(newStatus) : [newStatus];
  }
  return contractInfo.history;
};

export const rejectionBySalespersonAction: any = (contractInfo: any, metaData: any, rejectionReason: string) => (dispatch: any) => {
  dispatch({
    type: 'REJECTION_BY_SALESPERSON_REQUESTED',
    payload: {
      rejectionReason,
    },
  });
  contractInfo.history = getUpdatedContractHistory(contractInfo, metaData.docusignStatus, rejectionReason);
  return ContractService.saveContractInfo(contractInfo, metaData)
    .then((res: any) => {
      treatContractDataFormat(res.data);
      dispatch({
        type: 'REJECTION_BY_SALESPERSON_SUCCEEDED',
        payload: res.data,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'REJECTION_BY_SALESPERSON_ERROR',
        payload: err,
      });
    });
};

export const saveContractAction = (newInfo: any, metaData: any) => (dispatch: any) => {
  dispatch({
    type: 'AUTO_SAVE_CONTRACT_REQUESTED',
    payload: {
      newInfo,
      metaData,
    },
  });
  newInfo.history = getUpdatedContractHistory(newInfo, metaData.docusignStatus);
  newInfo.personalInfoSection = formatPartnerBody(newInfo.personalInfoSection);
  return ContractService.saveContractInfo(newInfo, metaData)
    .then((res: any) => {
      dispatch({
        type: 'AUTO_SAVE_CONTRACT_SUCCEEDED',
        payload: res.data,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'AUTO_SAVE_CONTRACT_ERROR',
        payload: err,
      });
    });
};

export const getAutosaveContractAction: any = (newInfo: any, metaData: any) => (dispatch: any) => {
  runDebouncedAutosave(newInfo, metaData, dispatch);
};

export const getContractInfoAction: any =
  (
    journeyId: string,
    estimate: any,
    estimateResults: CalculatorResult,
    availablePractices: Practice[],
    loggedInChannelPartner: ChannelPartner,
    producerInformation: any,
  ) =>
  (dispatch: any) => {
    dispatch({
      type: 'GET_CONTRACT_INFO_REQUESTED',
      payload: {
        growerId: producerInformation.opportunityId,
        estimateId: estimate.id,
      },
    });
    return ContractService.getContractInfo(journeyId, estimate, estimateResults, availablePractices, loggedInChannelPartner, producerInformation)
      .then((res: any) => {
        if (!res.contractId) {
          const metaData = {
            estimateId: estimate.id,
            envelopeId: res.envelopeId,
            docusignStatus: 'UNINITIALIZED',
            approvedBy: null,
            growerId: producerInformation.opportunityId,
            contractType: '',
            createdBy: '',
            contractRegion: res.contractRegion,
            contractVersionId: estimate.contractVersionId,
          };
          res.data.history = getUpdatedContractHistory(res.data, 'UNINITIALIZED');
          return ContractService.saveContractInfo(res.data, metaData).then((saveResult) => {
            return {
              contractId: saveResult.data.id,
              docusignStatus: saveResult.data.docusignStatus,
              contractType: saveResult.data.contractType,
              data: saveResult.data.data,
              contractRegion: saveResult.data.contractRegion,
              createdBy: saveResult.data.createdBy,
              contractVersionId: estimate.contractVersionId,
            };
          });
        }
        return res;
      })
      .then((res: any) => {
        treatContractDataFormat(res.data);
        if (estimate.contractVersionId) dispatch(initializeContractVersionAction(estimate.contractVersionId));
        dispatch({
          type: 'GET_CONTRACT_INFO_SUCCEEDED',
          payload: {
            createdBy: res.createdBy,
            contractRegion: res.contractRegion,
            estimateName: res.estimateName,
            contractInfo: res.data,
            contractId: res.contractId,
            docusignStatus: res.docusignStatus,
            contractType: res.contractType,
            approvedBy: res.approvedBy,
            docusignUpdate: res.docusignUpdate,
            contractVersionId: estimate.contractVersionId,
          },
        });
      })
      .catch((err: any) => {
        console.log(err);
        dispatch({
          type: 'GET_CONTRACT_INFO_ERROR',
          payload: err,
        });
      });
  };

export const salesApprovalRequest: any = (newInfo: any, metaData: any, t: TFunction) => (dispatch: any) => {
  dispatch({
    type: 'SALES_APPROVAL_REQUEST_REQUESTED',
  });
  newInfo.history = getUpdatedContractHistory(newInfo, metaData.docusignStatus);
  return ContractService.saveContractInfo(newInfo, metaData)
    .then((res: any) => {
      dispatch({
        type: 'SALES_APPROVAL_REQUEST_SUBMITTED',
        payload: res.data,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'SALES_APPROVAL_REQUEST_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: t('contractForm.error.title.contractError') ?? '',
        message: err.response.data.message,
        type: 'error',
        toastId: 'update-contract-status-request',
      });
    });
};

export const sendToRiskAssessment: any =
  (
    contractId: any,
    successMessage: string,
    isSendingToDocusign: boolean,
    getContractData: {
      journeyId: string;
      leadOrOpportunityId: string;
      estimate: any;
      estimateResults: CalculatorResult;
      availablePractices: Practice[];
      loggedInChannelPartner: ChannelPartner;
    },
    t: TFunction,
    i18n: i18n,
  ) =>
  (dispatch: any) => {
    dispatch({
      type: 'SEND_TO_RISK_ASSESSMENT_REQUESTED',
    });
    return ContractService.sendToRiskAssessment(contractId)
      .then(async (res: any) => {
        if (isSendingToDocusign && ['DOCUSIGN_APPROVAL', 'DOCUSIGN_PENDING_SALES'].indexOf(res.docusignStatus) === -1) {
          res = await retryGetContract({ journeyId: getContractData.journeyId, estimateId: getContractData.estimate.id });
        }
        treatContractDataFormat(res.data);
        dispatch({
          type: 'SEND_TO_RISK_ASSESSMENT_SUCCEEDED',
          payload: res,
        });
        AgoToast.showToast({
          title: t('contractForm.success.title.raSuccess') ?? '',
          message: successMessage,
          type: 'success',
          toastId: 'ra-submitted-success',
        });
      })
      .catch((err: any) => {
        const message = i18n.exists(`contractForm.error.raErrors.${err.response?.data?.message}`)
          ? t(`contractForm.error.raErrors.${err.response?.data?.message}`)
          : err.response?.data?.message ?? err.message;
        AgoToast.showToast({
          title: t('contractForm.error.title.riskAssessmentSubmitError') ?? '',
          message,
          type: 'error',
          toastId: 'ra-request',
        });
        dispatch({
          type: 'SEND_TO_RISK_ASSESSMENT_ERROR',
          payload: err,
        });
      });
  };

export function initializeContractVersionAction(contractVersionId: string) {
  return (dispatch: any) => {
    dispatch({
      type: 'INITIALIZE_CONTRACT_VERSION_REQUESTED',
    });
    return ContractService.getContractVersionById(contractVersionId)
      .then((contractVersion: IContractVersion) => {
        if (!contractVersion) throw new Error('Contract version not found');

        const rules: IVersionRules = contractVersion.rules ?? defaultVersionRules;
        return ContractService.getActiveContractVersion(contractVersion.country, rules).then((activeContractVersion: IContractVersion) => {
          dispatch({
            type: 'INITIALIZE_CONTRACT_VERSION_SUCCEEDED',
            payload: { contractVersion, activeContractVersion },
          });
        });
      })
      .catch((err: any) => {
        console.log(err);
        dispatch({
          type: 'INITIALIZE_CONTRACT_VERSION_ERROR',
          payload: err,
        });
      });
  };
}

const retryGetContract = (data: { journeyId: string; estimateId: string }, retry: number = 0) => {
  const timeout = 500 * 2 ** retry;
  return new Promise<any>((resolve) => {
    setTimeout(async () => {
      let contract = await ContractService.getAutoSavedContractInfo(data.journeyId, data.estimateId);
      if (['DOCUSIGN_APPROVAL', 'DOCUSIGN_PENDING_SALES'].indexOf(contract.docusignStatus) === -1) {
        // This is to prevent user from getting stuck if somehow the status does not get updated
        if (retry >= 5) {
          return resolve(contract);
        }
        contract = await retryGetContract(data, ++retry);
      }
      resolve(contract);
    }, timeout);
  });
};

export const getValidChannelPartners: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_VALID_CHANNEL_PARTNERS_REQUESTED',
  });
  return ContractService.getValidChannelPartners()
    .then((res: any) => {
      dispatch({
        type: 'GET_VALID_CHANNEL_PARTNERS_SUCCEEDED',
        payload: res,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_VALID_CHANNEL_PARTNERS_ERROR',
        payload: err,
      });
    });
};

const mergePractices = (practices: ContractPracticeList, defaultPractices: ContractPracticeList) => {
  Object.keys(defaultPractices).forEach((key) => {
    const defaultPractice = defaultPractices[key];
    if (!practices[key]) {
      practices[key] = defaultPractice;
    }
  });
  return practices;
};

export const getRiskAssessment: any = (contractId: any) => (dispatch: any) => {
  dispatch({
    type: 'GET_RISK_ASSESSMENT_REQUESTED',
  });
  const state: any = store.getState();
  const availablePractices = state.contract.defaultAvailablePractices;
  const defaultPractices = ContractService.getDefaultPractices(availablePractices, {});
  return ContractService.getRiskAssessment(contractId)
    .then((res: any) => {
      res.data.practices = mergePractices(res.data.practices, defaultPractices);
      res.data.personalInfoSection.channelPartnerName = res.data.personalInfoSection.channelPartnerName
        ? res.data.personalInfoSection.channelPartnerName
        : 'N/A Direct Sale';
      dispatch({
        type: 'GET_RISK_ASSESSMENT_SUCCEEDED',
        payload: res,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_RISK_ASSESSMENT_ERROR',
        payload: err,
      });
    });
};

export const clearContractStore = () => (dispatch: any) => {
  dispatch({
    type: 'CONTRACT_PAGE_DISMOUNTED',
  });
  return Promise.resolve();
};
